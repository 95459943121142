import React, { useEffect, useMemo, useState, useContext } from "react";
import EmployeeActivityInfo from "../../../../models/api/responses/EmployeeActivityInfo";
import { useParams } from "react-router";
import SVG from "react-inlinesvg";
import Grid from "../../../../shared/components/Grid/Grid";
import { getEmployeeActivities, addEmployeeActivity, updateEmployeeActivity, getActivityMonths, getActivityYears, fetchEmployeeByIdAction } from "../../../../redux/Action/EmployeeAction";
import EmployeeActivityForm from './EmployeeActivityForm';
import { Provider } from "react-redux";
import { modalService, toastService } from "@tg/react-ui-kit";
import store from "../../../../redux/store";
import { confirmModal } from "../../../../helpers/confirmModal"
import { ActivityType } from "../../../../enums/ActivityType"
import './EmployeeActivities.scss';
import { BehaviorSubject } from "rxjs";
import Filter from "../../../../shared/components/Filter/Filter";
import { useAppDispatch } from "../../../../shared/hooks/customHooks";
import { setPageDetailsAction } from "../../../../redux/Action/ApplicationAction";
import { useSelector } from "react-redux";
import { AuthContext } from './../../../../config/authorization/AuthContext';
import { EMPLOYEE_ACTIVITY_ADD, EMPLOYEE_ACTIVITY_BYPASS, EMPLOYEE_ACTIVITY_EDIT, VALIDATION_DATE } from "./../../../../config/authorization/PermissionsConstants";

interface MonthlyTimePeriod {
    month: string,
    date: number
}
export function EmployeeActivities() {

    const authContext = useContext(AuthContext);
    const canEditEmployeeAcivities = authContext && authContext.checkPermission([EMPLOYEE_ACTIVITY_EDIT]);
    const canAddEmployeeAcivities = authContext && authContext.checkPermission([EMPLOYEE_ACTIVITY_ADD]);
    const canBypassEmployeeAcivities = authContext && authContext.checkPermission([EMPLOYEE_ACTIVITY_BYPASS]);
    const [employeeactivities, setEmployeeActivities] = useState<EmployeeActivityInfo[]>([]);
    const [rowData, setRowData] = useState<EmployeeActivityInfo[]>([]);
    const [loadActivities, setLoadActivities] = useState<boolean>(true);
    const [tags, setTags] = useState<any>([]);
    const { id } = useParams();
    const employeeId = id;
    const dispatch = useAppDispatch();

    const employeeName = useSelector(
        (state:any)=> state.employeeById.data.employeeName
      )
    const [activityYears, setActivityYears] = useState<{name:number,id:number}[]>([]);
    const [montlyTimePeriod, setMonthlyTimePeriod] = useState<MonthlyTimePeriod[]>([]);


    const activityTypes: any = [{ id: 1, name: 'COE' }, { id: 2, name: 'Training' }]

    const getAvailableActivityYears = () => {
        getActivityYears().then(response => {
            const years = response && response.data.map((value: any) => ({ id: value.yearId, name: value.yearNumber }))
            setActivityYears(years)
          });
    }
    const getMonthlyTimePeriods = () => {
        getActivityMonths().then(response => {
            const timePeriods = response && response.data.map((value: any) => ({ month: value.timePeriodName, date: value.validationOfTimePeriod }))
            setMonthlyTimePeriod(timePeriods);
        });
    }

    const EditAccount = (props: any) => {

        const data = employeeactivities;

        const result = {} as any;
        const montlyData = data.filter(({ activityYear, activityMonth }) => (
            activityYear === props.data.activityYear && activityMonth === props.data.activityMonth
        ));

        montlyData.forEach(({ activityYear, activityMonth, allotedHours }) => {
            const key = `${activityYear}-${activityMonth}`;
            if (!result[key]) {
                result[key] = 0;
            }
            result[key] += allotedHours;
        });

        const formData: ResouceActivity = {
            employeeActivityId: props.data.employeeActivityId,
            activityType: props.data.activityType,
            month: props.data.timePeriodId,
            year: props.data.activityYearId,
            hours: props.data.allotedHours,
            comments: props.data.comments
        }

        const day = montlyTimePeriod.find(s => s?.month === props.data.activityMonth)?.date;

        const canEdit = (day && day >= new Date().getDate()) && (props.data.activityYear===new Date().getFullYear()) && (props.data.timePeriodId===new Date().getMonth()+1)
        return (
            canEdit ?
                <span>
                    <SVG
                        src={process.env.PUBLIC_URL + "/assets/icons/edit.svg"}
                        onClick={() => { openAddEmployeeActivities(formData, result, false) }}
                    ></SVG>{" "}
                </span> : <></>);
    };

    useEffect(() => {
        getMonthlyTimePeriods();
        getAvailableActivityYears();
        getEmployeeActivities({ employeeId: employeeId }).then(response => {
            setEmployeeActivities(response.data);
            setRowData(response.data);
        })
    }, [employeeId, loadActivities]);

    useEffect(() => {
        OnFilterLoad()
    }, [employeeactivities])

    useEffect(() => {
        dispatch({ type: "RESET_ACTIVITY_FILTER_BODY", payload: [] })
    }, [])

    useEffect(()=>{
        employeeId &&
        fetchEmployeeByIdAction(employeeId)
          .then((response: any) => {
            dispatch({
              type: "FETCH_EMPLOYEE_BY_EMPLOYEE_ID",
              payload: response.data,
            });
          })
          .catch((error: any) => {
            toastService.addToast({
              text: error.message || "Failed to fetch employee data",
              options: { timeout: 5 },
              level: "error",
            });
          });
      }, [])


    useEffect(() => {
        dispatch(
          setPageDetailsAction({
            pageTitle: employeeName,
            breadCrumb: [
              {
                title: "Employees",
                url:"/employee",
              },
              {
                title: employeeName,
                url: `/employee/${id}/employee360/overview`,
              },
              { title: "activities" },
            ],
          })
        );
      }, [employeeName]);

      const ShowActionButton = () => {
        const todayDate = new Date();
        let isEmployeeActivitiesDone:any[]=[];
        employeeactivities && employeeactivities.forEach(employeeActivity=>{
            let days = montlyTimePeriod.find(s => s?.month === employeeActivity.activityMonth)?.date;
            if(
                (days && days >= new Date().getDate()) && 
                (employeeActivity.activityYear === new Date().getFullYear()) && 
                (employeeActivity.timePeriodId === todayDate.getMonth()+1)){

                isEmployeeActivitiesDone.push(employeeActivity);
            }
        });
       
        if(isEmployeeActivitiesDone.length > 0){
            if((todayDate.getDate().toString() <= VALIDATION_DATE && canEditEmployeeAcivities) || canBypassEmployeeAcivities){
                return true;
            }
            else{
                return false;
            }
        }
      }

    const columnDefs = [
        {
            field: "activityYear",
            headerName: "Activity Year",
            cellRenderer: (props: any) => {
                return <span className="grid-column-name">{props?.value}</span>;
            },
        },
        {
            field: "activityMonth",
            headerName: "Activity Month",
        },
        {
            field: "activityType",
            headerName: "Activity Type",
            cellRenderer: (props: any) => {
                const value = ActivityType[props.value]
                return <span>{value}</span>;
            }
        },
        {
            field: "allotedHours",
            headerName: "Allocated Hours",
        },
        {
            field: "comments",
            headerName: "Comments",
            cellRenderer: (props: any) => {
                const value = props.value
                return value !== '' ? 
                    <span className="note-column" title={value}>{value}</span>
                    : "NA";
            }
        },
        {
            field: "accountId",
            headerName: "ACTIONS",
            cellRenderer: EditAccount,
            sortable: false,
            hide: (!ShowActionButton())
        },
    ];

    const GridButton = () => {
        return (
            <>
                { ((new Date().getDate().toString() <= VALIDATION_DATE && canAddEmployeeAcivities) || canBypassEmployeeAcivities) && (
                    <button className="grid-action-button"
                        onClick={() => {
                            const data = employeeactivities;
                            const result = {} as any;
                            data.forEach(({ activityYear, activityMonth, allotedHours }) => {
                                const key = `${activityYear}-${activityMonth}`;
                                if (!result[key]) {
                                    result[key] = 0;
                                }
                                result[key] += allotedHours;
                            });
                            const formData: ResouceActivity = {
                                year: activityYears.find(s=>s.name===new Date().getFullYear())?.id,
                                month: new Date().getMonth()+1,
                            }
                            openAddEmployeeActivities(formData, result, true)
                        }}
                        >
                    Add Employee Activity
                    </button>
                )}
            </>
        );
    };

    const OnFilterLoad = () => {
        const tagsData = [
            {
                name: "Activity Year",
                id: 2,
                type: "Text",
                field: "activityYear",
                data: employeeactivities?.map((val: any) => val?.activityYear + "").filter((value: any, index: any, array: any) => array.indexOf(value) === index),
            },
            {
                name: "Activity Month",
                id: 3,
                type: "Text",
                field: "activityMonth",
                data: employeeactivities?.map((val: any) => val?.activityMonth + "").filter((value: any, index: any, array: any) => array.indexOf(value) === index),
            },
            {
                name: "Activity Type",
                id: 4,
                type: "Text",
                field: "activityType",
                data: employeeactivities?.map((val: any) => ActivityType[val?.activityType]).filter((val) => val).filter((value: any, index: any, array: any) => array.indexOf(value) === index),
            }
        ];
        setTags(tagsData)
    }

    const $dirty = useMemo(() => new BehaviorSubject<boolean>(false), [])
    const openAddEmployeeActivities = (formData: ResouceActivity | null, activityHours: {}, isCreate: boolean) => {
        modalService.open({
            title: isCreate ? "Add Employee Activity" : "Update Employee Activity",
            element: (
                <Provider store={store}>
                    <EmployeeActivityForm
                        formId="add_employee_activities"
                        formData={formData}
                        $dirty={$dirty}
                        activityHours={activityHours}
                        onSubmit={(data: any) => {
                            if (!$dirty.getValue()) {
                                toastService.addToast({
                                    text: "No Changes detected",
                                    options: { timeout: 5 },
                                    level: "success",
                                });
                                modalService.close();
                                return;
                            }
                            if (isCreate) {
                                addEmployeeActivity(
                                    {
                                        employeeId: employeeId,
                                        timeperiodId: data.month,
                                        activityTypeId: data.activityType,
                                        allotedHours: data.hours,
                                        comments: data.comments,
                                        timeperiodYear: data.year
                                    }
                                ).then((data: any) => {
                                    ;
                                    toastService.addToast({
                                        text: "Activity Added  successfully",
                                        options: { timeout: 5 },
                                        level: "success",
                                    });
                                    setLoadActivities(!loadActivities);
                                    modalService.close();
                                })
                                    .catch((error: any) => {
                                        error.json().then((errResp: any) => {
                                            
                                            toastService.addToast({
                                                text:
                                                errResp.message ||
                                                    "Failed to Add Activities",
                                                options: { timeout: 5 },
                                                level: "error",
                                            });
                                          });
                                    })
                            }
                            else {
                                updateEmployeeActivity({
                                    employeeActivityMapId: formData && formData.employeeActivityId
                                    , allotedHours: data.hours ?? (formData && formData.hours)
                                    , comments: data.comments ?? (formData && formData.comments)
                                }).then((data: any) => {
                                    toastService.addToast({
                                        text: "Activity Updated  successfully",
                                        options: { timeout: 5 },
                                        level: "success",
                                    });
                                    setLoadActivities(!loadActivities);
                                    modalService.close();
                                })
                                    .catch((error: any) => {
                                        error.json().then((errResp: any) => {
                                            toastService.addToast({
                                                text:
                                                    errResp.message ||
                                                    "Failed to Update Activity",
                                                options: { timeout: 5 },
                                                level: "error",
                                            });
                                          });
                                    })
                            }
                        }}
                        isCreate={isCreate}
                    />
                </Provider>
            ),
            onClose: () => {
                let isDirty = $dirty.getValue();
                isDirty
                    ? confirmModal(
                        () => {
                            modalService.close();
                        },
                        () => { },
                        "Are you sure to close the form? the changes made won't be saved."
                    )
                    : modalService.close();
            },
            actionButtons: [
                {
                    class: "btn btn-primary modal-cancel-button",
                    text: "Cancel",
                    action: () => {
                        let isDirty = $dirty.getValue();
                        isDirty
                            ? confirmModal(
                                () => {
                                    modalService.close();
                                },
                                () => { },
                                "Are you sure to close the form? the changes made won't be saved."
                            )
                            : modalService.close();
                    }
                },
                {
                    form: "add_employee_activities",
                    class: "btn btn-primary modal-save-button",
                    text: "Save",
                },
            ],
        });
    };



    return (
        <>
            <Grid rowData={rowData}
                rowCount={rowData.length}
                columnDefs={columnDefs}
                showFilterIcon={true}
                actionButtons={<GridButton />}
                filterTemplate={
                    <Filter
                        data={tags}
                        title="Activities"
                        storeFilterBody="activityFilterBody"
                        onFilterMount={() => {
                            console.log("onFilterMount")
                            OnFilterLoad();
                        }}
                        onFilterDispatch={(type, payload) => {
                            console.log("onFilterDispatch")
                            if (type === "RESET_FILTER_BODY") {
                                console.log("resetting")
                                dispatch({ type: "RESET_ACTIVITY_FILTER_BODY", payload: payload })
                            } if (type === "UPDATE_FILTER_BODY") {
                                dispatch({ type: "UPDATE_ACTIVITY_FILTER_BODY", payload: payload })
                            } else {

                            }
                        }}
                        rowData={(e) => {
                            if (e && e.filters) {
                                let filters = e.filters;
                                const data = employeeactivities;
                                const filteredData = data.filter((item) => {
                                    return filters.every((filter: any) => {
                                        if (filter.fieldName === 'activityYear') {
                                            const fieldValue = item.activityYear + "";
                                            if (filter.condition === "IN") {
                                                return filter.values.includes(fieldValue);
                                            } else if (filter.condition === "NOT IN") {
                                                return !filter.values.includes(fieldValue);
                                            }
                                        }
                                        if (filter.fieldName === 'activityMonth') {
                                            const fieldValue = item.activityMonth + "";
                                            if (filter.condition === "IN") {
                                                return filter.values.includes(fieldValue);
                                            } else if (filter.condition === "NOT IN") {
                                                return !filter.values.includes(fieldValue);
                                            }
                                        }
                                        if (filter.fieldName === 'activityType') {

                                            const fieldValue = item.activityType && ActivityType[item.activityType];

                                            if (filter.condition === "IN") {
                                                return filter.values.includes(fieldValue);
                                            } else if (filter.condition === "NOT IN") {
                                                return !filter.values.includes(fieldValue);
                                            }
                                        }
                                    });
                                });
                                setRowData(filteredData);
                            }
                            else {
                                setRowData(employeeactivities);
                            }
                        }}
                    />
                }
            />
        </>
    );
}


export interface ResouceActivity {
    employeeActivityId?: number
    activityType?: number,
    month?: number,
    year?: number
    hours?: number;
    comments?: string
}
