class EmailId {
  emailID?: string;
}

class MobileNumber {
  mobileNumber?: string;
}

class AllocationRequest {
  fullName?: string;
  accountId?: number;
  contactTypeId?: number;
  usageName?: string;
  gender?: string;
  department?: string;
  jobTitle?: string;
  primaryEmailAddress?: EmailId = new EmailId();
  secondaryEmails?: EmailId[] = [];
  mobileNumbers?: MobileNumber[] = [];
  linkedInID?: string;
  notes?: string;
  startDate?: string;
  endDate?: string;
  statusId?: number;
}

class ProjectId {
  projectId?: number;
}

export default class ProjectContact {
  allocationRequest?: AllocationRequest = new AllocationRequest();
  projectId?: ProjectId = new ProjectId();
}
