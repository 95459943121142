import React, { useState } from "react";
import { useEffect } from "react";
import TeamsAuthService from "../../../services/TeamsAuthService";
import { useNavigate } from "react-router";
import { AuthProvider } from "../../../config/authorization/AuthContext";
import RoutesConfig from "../../../config/routes/routesConfig";
import Spinner from "react-bootstrap/Spinner";
export default function TeamsTab() {
  const [isTokenSet, setIsTokenSet] = useState<boolean>(false);
  const navigate = useNavigate();
  async function OnLoad() {
    TeamsAuthService.authState.isTeamsApp = true;
    if (!TeamsAuthService.isLoggedIn()) {
      TeamsAuthService.getAccessToken()
        .then(() => {
          if (TeamsAuthService.isLoggedIn()) {
            setIsTokenSet(true);
          } else {
            navigate("/unauthorized");
          }
        })
        .catch((error) => {
          navigate("/unauthorized");
        });
    } else {
      setIsTokenSet(true);
    }
  }
  useEffect(() => {
    OnLoad();
  }, []);

  return (
    <div className="App" id="app">
      <>
        {isTokenSet ? (
          <AuthProvider>
            <RoutesConfig />
          </AuthProvider>
        ) : (
          <div className="loading-container">
            <div className="loading-message">
              Getting Data ...
              <Spinner
                className="getting-data-spinner"
                animation="grow"
                variant="primary"
              />
            </div>
          </div>
        )}
      </>
    </div>
  );
}
