import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";
import "./styles/fonts/AvertaDemo-Regular.ttf";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import UnAuthenticatedRoutesConfig from "../src/config/routes/unAuthenticated";
import TeamsTab from "./views/components/TeamsApp/TeamsTab"
export var msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
if (window.parent === window.self) 
{
  root.render(
    // <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <AuthenticatedTemplate>
            <App />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <UnAuthenticatedRoutesConfig />
          </UnauthenticatedTemplate>
        </MsalProvider>
      </BrowserRouter>
    </Provider>
    // </React.StrictMode>
  );
}
else
{
  root.render(
    // <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <TeamsTab />        
      </BrowserRouter>
    </Provider>
    // </React.StrictMode>
  );
}

