class ProjectNames 
{
    id?: number;
    name?: string;
}
export default class Contact {
    contactId?: number;
    fullname?: string;
    accountName?: string;
    associationType?: string;
    contactType?: string;
    usageName?: string;
    gender?: string;
    department?: string;
    jobTitle?:  string;
    primaryEmail?: string;
    secondaryEmails?: string;
    mobileNumbers?: string;
    linkedInId?: string;
    notes?: string;
    startDate?: string;
    endDate?: string;
    status?: string;
    numberOfProjects?: string;
    projectNames?: ProjectNames[];
}