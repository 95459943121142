import React from 'react';
import { Container } from 'react-bootstrap';

export default function PrivacyPage() {
  return (
    <Container className="page-container">
      <h1 className="page-title">Privacy Policy</h1>
      <p>
        At OurCompany, we take your privacy seriously. This Privacy Policy
        outlines the types of personal information we receive and collect when
        you use our website, how we use and safeguard that information, and the
        choices you have regarding your information. By accessing or using our
        website, you consent to the practices described in this Privacy Policy.
      </p>
      <p>

      </p>
    </Container>
  );
};
