import Address from "./Address";
import BusinessDetails from "./BusinessDetails";
import SocialMedia from "./SocialMedia";

export default class Account {
    accountId: number | null = null;
    accountName: string | null = null;
    accountCode: string | null = null;
    accountTypeId: number | null = null;
    statusId: number | null = null;
    startDate: Date | null = null;
    expectedEndDate: Date | null = null;
    actualEndDate: Date | null = null;
    accountOwnerIds: number[] | null = [];
    accountOwners: any[] | null = [];
    businessDetails: BusinessDetails = new BusinessDetails();
    address: Address = new Address();
    socialMedia: SocialMedia = new SocialMedia();
}