import React, { useCallback, useEffect, useState } from "react";
import { loaderService, modalService, TgInput, toastService } from "@tg/react-ui-kit";
import { useDropzone } from "react-dropzone";
import { createEmployeeDocument } from "../../../../redux/Action/EmployeeAction";
import SVG from "react-inlinesvg";
import "./EmployeeDocument.scss";

export function FileDragandDrop(props: any) {
  const [fileExtension, setFileExtension] = useState("");
  const [displayFileName, setDisplayFileName] = useState("");
  const [document, setDoucment] = useState<File | null>(null);
  const [errors, setErrors] = useState<Partial<{
    documentName: string,
    document: string,
  }>>({})

  const [allowDocumentUpload,setAllowDocumentUpload]=useState(true);
  const [documentName, setDocumentName] = useState("");
  function setLoading(isLoading: boolean){
    if (isLoading) {
      loaderService.showSync();
    } else {
      loaderService.hideSync();
    }
  }

  const setDirty = () => {
    props?.$dirty && !props?.$dirty.getValue() && props?.$dirty.next(true);
  };

  const onDrop = useCallback((files: Array<File>) => {
    setDirty();
    const file=files[0];
    const fileSize=(file.size / 1024)/1024;
      if(fileSize>25){
        setErrors({...errors,document:"The file is too large to be uploaded. The maximum size allowed is 25 MB"})
      }
      else{
        setErrors({...errors,document:undefined})
        setAllowDocumentUpload(false);
      }
    setDoucment(files[0]);
    setDisplayFileName(files[0].name.slice(0, files[0].name.lastIndexOf(".")));
    setFileExtension(files[0]?.name.slice(files[0].name.lastIndexOf(".") + 1));
  }, []);

  const removeFile = () => {
    setAllowDocumentUpload(true);
    setDoucment(null);
    setErrors({...errors,document:undefined})
  };

  const employeeId = props.employeeId

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop,disabled:!allowDocumentUpload});

  const uploadDocument = () => {
    const currentErrors: Record<string, string> = {}
    if (documentName.length === 0) {
      currentErrors["documentName"] = "Document Name is Required"
    }
    else if(documentName.length>24){
      currentErrors["documentName"] = "Document Name Should be less than 25 characters";
    }

    if (!document) {
      currentErrors["document"] = "Please select a document"
    } else {
      // const k =Math.floor(Math.log(document.size) / Math.log(1024));
      const documentSize=(document.size / 1024)/1024;
      if(documentSize>25){
        currentErrors["document"] = "The file is too large to be uploaded. The maximum size allowed is 25 MB";
      }
    }


    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors)
      return;
    } else {
      setErrors({})
    }

    if (document) {
      const formData = new FormData();
      formData.append('file', document);
      formData.append('employeeId', employeeId + '');
      formData.append('description', documentName);
      setLoading(true);
      createEmployeeDocument(formData).then(() => {
        toastService.addToast({
          text: "Document Uploaded  successfully",
          options: { timeout: 5 },
          level: "success",
        });
        props.loadEmployeeDocument()
        modalService.close();
      }).catch((error) => {
        error.json().then((errResp: any) => {
          toastService.addToast({
            text:
              errResp.errors ||
              errResp.message ||
              "Failed to Upload Document",
            options: { timeout: 5 },
            level: "error",
          });
        });
      }).finally(()=>{
        setLoading(false);
      })
    }
    else {
      console.log('document not found');
    }
  };
  return (
    <div className="popup">
      <TgInput
        type="text"
        placeHolder="Document value"
        withoutClear={true}
        value=""
        label="Document Name"
        required
        onInput={(e: any) => { 
          const val=(e.target as HTMLInputElement).value;
          setDocumentName(val);
          setDirty();
          if (val.length === 0) {
            setErrors({...errors,documentName:"Document Name is Required"})
          }
          else if(val.length>24){
            setErrors({...errors,documentName:"Document Name Should be less than 25 characters"})
          }
          else{
            setErrors({...errors,documentName:undefined})
          } 
        }}
      />
      {errors.documentName && (<span className="error-msg"> {errors.documentName} </span>)}

      <div className="fileUpload-container">
        <div className="fileUpload">
          <div className="drag-container" { ...getRootProps()}>
            <input {...getInputProps()} />

            <SVG src={process.env.PUBLIC_URL + "/assets/icons/cloudUpload.svg"}></SVG>

            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <>
                <p>
                  Drag and Drop the file or select the file to be uploaded which
                  has employee list
                </p>
                <div>
                  <button  disabled={!allowDocumentUpload} type="button" className="upload-button">
                    Choose File
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {errors.document && (<span className="error-msg"> {errors.document} </span>)}
      <div
        className={
          "bottom-container " +
          (document !== null ? "expand" : "right")
        }
      >
        {document !== null && (
          <div className="file-details">
            <div>
              <SVG
                src={process.env.PUBLIC_URL + "/assets/icons/file.svg"}
              ></SVG>{" "}
            </div>
            <div className="file-name">
              {displayFileName}
              <div className="ext">
                <span>{fileExtension}</span>
              </div>
            </div>
            <button onClick={removeFile}>
              <SVG
                src={process.env.PUBLIC_URL + "/assets/icons/delete.svg"}
              ></SVG>
            </button>
          </div>
        )}

        <button
          type="button"
          onClick={uploadDocument}
          className="upload-button "
        >
          Save Document
        </button>
      </div>
    </div>
  );
}
