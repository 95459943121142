class ContactInfo {
    contactId?: number;
    fullname?: string;
    accountId?: number;
    accountName?: string;
    associationTypeId?: number;
    contactTypeId?: number;
    usageName?: string;
    gender?: string;
    department?: string;
    jobTitle?: string;
    primaryEmail?: string;
    secondaryEmails?: string;
    mobileNumbers?: string;
    linkedInId?: string;
    notes?: string;
    startDate?: string;
    endDate?: string;
    statusId?: number;
    numberOfProjects?: number;
    projectNames?:[];
  }


export default class FetchContact {
    totalCount?: number;
    pageNumber?: number;
    pageSize?: number;
    totalPages?: number;
    contacts?: ContactInfo[];
}