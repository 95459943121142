import React, { useEffect, useState } from "react";
import { TgDropdownTemplate } from "@tg/react-ui-kit";
import { ConvertToValueObject } from "../../../helpers/ConvertToValueObject";
interface DropDownTemplateProps{
  value?: any;
  onChange?: (e: any) => any;
  required?: boolean;
  label?: string;
  placeholder?: string;
  dropdownList: any[];
  primaryField: string;
  withOutMapValue?: boolean;
  disabled?: boolean;
  canClear?: boolean;
  canSearch?:boolean;
  returnValue?:boolean;
}
export default function DropDownTemplate(props: DropDownTemplateProps) {
  const [dropdownList, setDropdownList] = useState<any>([]);
  const onSelect = (selectedValue: any) => {
    let selectedObj = dropdownList.find(
      (obj: any) => obj[props.primaryField] === selectedValue
    );
    props.onChange && (
      props.returnValue ? props.onChange(selectedValue) : props.onChange((selectedObj && selectedObj.id) || selectedValue))
  };
  const selectedValue = (value: any) => {
    if (!props?.withOutMapValue) {
      let selectedOption = dropdownList?.find(
        (obj: any) => obj.id === props.value
      );
      return selectedOption && selectedOption[props.primaryField];
    }
    return value;
  };

  useEffect(() => {
    setDropdownList(props?.dropdownList);
  }, [props.dropdownList, props.primaryField]);
  return (
    <>
    <div className="buzz-dropdown-template">
      <TgDropdownTemplate
        label={props?.label || "label"}
        placeholder={props?.placeholder}
        items={ConvertToValueObject(dropdownList, props?.primaryField)}
        value={selectedValue(props?.value)}
        onValueSelected={onSelect}
        canSearch={props.canSearch}
        attachToParent={true}
        dropdownIcon={process.env.PUBLIC_URL + "/assets/icons/vector.svg"}
        required={props.required}
        disabled={props.disabled}
        matchTriggerWidth={true}
        allowUnselect
        />
    </div>
    </>
  );
}
