
import React, { FormEvent, useEffect, useState } from "react";
import { TgInput } from "@tg/react-ui-kit";
import DropDownTemplate from "../../../../shared/components/DropDownTemplate/DropDownTemplate";
import { getActivityMonths, getActivityYears } from "../../../../redux/Action/EmployeeAction";
import { ResouceActivity } from "./EmployeeActivities";
import { ActivityType } from "../../../../enums/ActivityType";
import { BehaviorSubject } from "rxjs";
import { allowOnlyNumerics } from "../../../../helpers/allowOnlyNumerics";

interface ResourceActivityFormProps {
  formId?: string;
  onSubmit?: (data: any) => void;
  formData: any
  isCreate?: boolean;
  $dirty?: BehaviorSubject<boolean>
  activityHours: any
}

interface DataMap {
  id: number;
  name: string;
}
export default function EmployeeActivityForm(props: ResourceActivityFormProps) {

  const [activity, setResourceActivity] = useState<ResouceActivity>({} as ResouceActivity)
  const [activityYears, setActivityYears] = useState<DataMap[]>([]);
  const [activityMonths, setActivityMonths] = useState<DataMap[]>([]);
  const [errorMsg, setErrorMsg] = useState<any>();
  const [availableHours, setAvailableHours] = useState(0);
  const [maxAllowedHours, setMaxAllowedHours] = useState(0);

  const activityTypes: any = [{ id: 1, name: 'COE' }, { id: 2, name: 'Training' }]

  const setDirty = () => {
    props?.$dirty && !props?.$dirty.getValue() && props?.$dirty.next(true);
  };

  useEffect(() => {
    getActivityYears().then(response => {
      const months = response && response.data.map((value: any) => ({ id: value.yearId, name: value.yearNumber }))
      setActivityYears(months)
    })

    getActivityMonths().then(response => {
      const months:DataMap[] = response && response.data.map((value: any) => ({ id: value.timePeriodId, name: value.timePeriodName }))
      setActivityMonths(months);
    });

    setResourceActivity(props.formData)
  }, []);

  useEffect(()=>{
    if (!props.isCreate) {
      const availableHours = 160 - props.activityHours[`${activityYears.find(s=>s.id===props?.formData.year)?.name}-${activityMonths.find(s => s.id === props.formData.month)?.name}`]
      !props.isCreate && setAvailableHours(availableHours)
      !props.isCreate && setMaxAllowedHours(availableHours + props.formData.hours)
    }
  },[activityYears,activityMonths])


  const validate = () => {
    const response = activity ?? {};
    const errorResponse = activityFormValidators(response);
    setErrorMsg(errorResponse);
    if (errorResponse === null) {
      props.onSubmit && props.onSubmit(activity);
    }
  };

  const activityFormValidators = (data: ResouceActivity) => {
    let errorMsgs: any = {}
    let isValid = true;

    if (data.year === undefined || data.year === null) {
      errorMsgs.year = "Activity Year Is Required";
      isValid = false;
    }

    if (data.month === undefined || data.month === null) {
      errorMsgs.month = "Activity Month Is Required";
      isValid = false;
    }

    if (data.hours === undefined) {
      errorMsgs.hours = "Activity Hours Is Required";
      isValid = false;
    } else if (data.hours === 0) {
      errorMsgs.hours = "Activity Hours must be greater than 0";
      isValid = false;
    }
    else if (data.hours > 0) {
      if (!props.isCreate && data.hours > maxAllowedHours) {
        errorMsgs.hours = `Activity Hours cannot be greater than ${maxAllowedHours}`
        isValid = false;
      }
      else if (props.isCreate && !(data.month === undefined || data.month === null) && !(data.year === undefined || data.year === null)) {
        const key = activityYears.find(y => y.id === data.year)?.name + "-" + activityMonths.find(s => s.id === data.month)?.name
        if(props.activityHours[key] === undefined && data.hours>160){
          errorMsgs.hours = `Activity Hours cannot be greater than 160`;
          isValid = false;
        }
        else if (props.activityHours && data.hours > (160 - props.activityHours[key])) {
          errorMsgs.hours =props.activityHours[key]===160? `Maximum Activity Hours already assigned` :`Activity Hours cannot be greater than ${(160 - props.activityHours[key])}`
          isValid = false;
        }
      }
    }
    if (data.activityType === undefined || data.activityType === 0) {
      errorMsgs.activityType = "Activity Type Is Required";
      isValid = false;
    }

    return isValid ? null : errorMsgs;
  }

  return (
    <div>
      <form
        id={props.formId}
        name={props.formId}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          validate()
          // props.onSubmit && props.onSubmit(activity)
        }}
        onChange={() => {
          setDirty();
        }}>
        <div className="row buzz-form-container">
          <div className=" col-lg-6 col-md-12 p-2">
            <DropDownTemplate
              primaryField="name"
              value={activityYears.find(s=>s.id===props?.formData.year)?.name}
              withOutMapValue
              required
              canClear
              dropdownList={activityYears}
              label="Year"
              disabled
              placeholder="Select Activity Year"
              onChange={(id: number) => {
                const currentState: ResouceActivity = { ...activity }
                currentState.year = id;
                setResourceActivity(currentState);
                setDirty();
              }} />
            {errorMsg && errorMsg.year && (
              <span className="error">{errorMsg.year}</span>
            )}
          </div>
          <div className=" col-lg-6 col-md-12 p-2">
            <DropDownTemplate
              primaryField="name"
              value={ activityMonths.find(s=>s.id===props?.formData.month)?.name}
              withOutMapValue
              required
              canClear
              dropdownList={activityMonths}
              label="Month"
              disabled
              placeholder="Select Activity Month"
              onChange={(id: number) => {
                const currentState: ResouceActivity = { ...activity }
                currentState.month = id;
                setResourceActivity(currentState);

                setDirty();
              }} />
            {errorMsg && errorMsg.month && (
              <span className="error">{errorMsg.month}</span>
            )}
          </div>
          <div className=" col-lg-6 col-md-12 p-2">
            <TgInput
              label={"Activity Hours"}
              placeHolder="Enter Activity Hours"
              required
              withoutClear={true}
              value={props.isCreate ? "" : props?.formData.hours}
              onKeyDown={allowOnlyNumerics}
              errorMsg={errorMsg && errorMsg.hours}
              onInput={(e: FormEvent) => {
                let val = (e.target as HTMLInputElement).value;

                const currentState: ResouceActivity = { ...activity }
                currentState.hours = +val;
                setResourceActivity(currentState);
                setDirty();
              }}
            />
          </div>
          <div className=" col-lg-6 col-md-12 p-2">
            <DropDownTemplate
              primaryField="name"
              withOutMapValue
              required
              canClear
              disabled={!props.isCreate}
              dropdownList={activityTypes}
              label="Activity Type"
              placeholder="Select Activity Type"
              value={!props.isCreate && ActivityType[props?.formData.activityType]}
              onChange={(id: number) => {
                const currentState: ResouceActivity = { ...activity }
                if(id+''===''){
                  currentState.activityType = 0;
                }
                else{
                  currentState.activityType = id;
                }
                setResourceActivity(currentState);

                setDirty();
              }} />
            {errorMsg && errorMsg.activityType && (
              <span className="error">{errorMsg.activityType}</span>
            )}
          </div>
          <br></br>
          <br></br>
          <div className=" col-lg-12 col-md-12 p-2">
            <TgInput
              label="Note"
              placeHolder="Note"
              withoutClear={true}
              value={props.isCreate ? "" : props?.formData.comments}
              onInput={(e: FormEvent) => {
                let val = (e.target as HTMLInputElement).value;

                const currentState: ResouceActivity = { ...activity }
                currentState.comments = val;
                setResourceActivity(currentState);
                setDirty();
              }}

            />
          </div>
        </div>
      </form>
    </div>
  )
}

