import React from "react";
import {
  TgInput,
  TgDropdownTemplate,
  TgTagsInput,
  TgDatePicker,
} from "@tg/react-ui-kit";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./filterbody.scss";
import { FilterBodyProps } from "./filterbodyprops";
import { formatDate } from "../../../helpers/formatdate";
import { allowOnlyNumerics } from "../../../helpers/allowOnlyNumerics";
import { useDispatch, useSelector } from "react-redux";
import { confirmModal } from "../../../helpers/confirmModal";
import { APPLICATION_CONSTANTS } from "../../../constants/applicationConstants";
function FilterBody(props: FilterBodyProps) {
  const [filterTags, setFilterTags] = useState<any[]>();
  const [tags, setTags] = useState<any>();
  let [selectedArray, setSelectedArray] = useState<any>([]);
  let [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [filterRequest, setFilterRequest] = useState<any>({});
  const [showModal, isShowModal] = React.useState<boolean>(props.showModal);
  let [selectedValue, setSelectedValue] = useState<any>([]);
  let [dropDownValueSelected, setDropDownValueSelected] = useState<any>([]);
  const [filterFieldError,setFilterFieldError] = useState<string>("");
  let storefilterBody = useSelector((state: any) => state[props.storeFilterBodyString] || []);
  const dispatch = useDispatch();
  const textDropdown = [
    {
      key: 1,
      value: "IN",
    },
    {
      key: 2,
      value: "NOT IN",
    },
  ];
  const conditionDropdown = [
    {
      key: 1,
      value: "<",
    },
    {
      key: 2,
      value: "<=",
    },
    {
      key: 3,
      value: ">",
    },
    {
      key: 4,
      value: ">=",
    },
    {
      key: 5,
      value: "==",
    },
    {
      key: 6,
      value: "BETWEEN",
    },
  ];
  
  const sortingTags=(tags: any[])=>{
    tags.sort((a:any, b:any) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  const filteringCriteria = (selectedList: any) => {
    setSelectedArray([...selectedList]);
    let selectedField = selectedList.map((v: any) => v.field);
    let removedElement: any = [];
    removedElement = selectedValue.filter(
      (val: any) => !selectedField.includes(val.field)
    );
    let removedIndex = selectedValue
      .map((e: any) => e.name)
      .indexOf(removedElement[0]?.name);
    selectedValue.splice(removedIndex, 1);
    setSelectedValue([...selectedValue]);
    const rm = tags?.filter((val: any) => !selectedField.includes(val.field));
    tags?.find((v: any) => {
      if (rm.includes(v)) {
        v.startValue = null;
        v.endValue = null;
        v.condition = null;
        v.endErrorMsg = null;
        v.startErrorMsg = null;
        v.values = [];
      }
    });

    let dropdownValues = dropDownValueSelected.filter(
      (e: any) => !selectedField.includes(e)
    );
    if (dropDownValueSelected.indexOf(dropdownValues[0]) >= 0) {
      dropDownValueSelected.splice(
        dropDownValueSelected.indexOf(dropdownValues[0]),
        1
      );
    }
    setDropDownValueSelected([...dropDownValueSelected]);
    sortingTags(tags)
    setTags(tags);
  };

  const handleSelectedTagsChange = (data : any)  => {
    if (!props?.$dirty?.getValue()) {
      if (data?.length !== selectedTags?.length) {
        setDirty();
      }
    }
    selectedArray?.map((obj: any) => {
      if (obj.type === "Text") {
        if (data.length === 0) {
          obj.isInitialState = undefined;
        } else {
          data?.map((x: any) => {
            if (obj.field !== x.field) {
              obj.isInitialState = undefined;
            }
          });
        }
      }
    });
    if(data.length>0)
    {
      setFilterFieldError("");
    }
    else{
      setFilterFieldError("This field is required");
    }
    filteringCriteria(data);
  }
  const handleTagsChange = (data: any, selectedTag: any) => {
    if (!props.$dirty?.getValue()) {
      if (data.length === selectedTag?.selectedTags?.length) {
        var isEqual = true;
        selectedTag?.selectedTags?.forEach((obj: any, index: any) => {
          if (obj?.name !== data[index]?.name) {
            isEqual = false;
            return;
          }
        });
        !isEqual && setDirty();
      } else {
        setDirty();
      }
    }
    if (selectedTag?.isInitialState && data.length > 0) {
      var isModified = false;
      if (data.length != selectedTag?.selectedTags?.length) {
        isModified = true;
      } else {
        selectedTag?.selectedTags?.forEach((obj: any, index: any) => {
          if (obj?.name !== data[index]?.name) {
            isModified = true;
            return;
          }
        });
      }
      if (isModified) {
        selectedTag.isInitialState = false;
      }
    }
    let t: any = [];
    data.map((val: any) => {
      t.push(val.name);
    });
    selectedValue.find((val: any) => {
      if (val.field === selectedTag.field && selectedTag.isInitialState == false) {
        val.values = t;
        selectedTag.values = t;
      }
    });
    var temp = null;
    if (data.length <= 0) {
      temp = "This field is required";
    }
    if (selectedTag?.isInitialState) {
      temp = null;
    }
    if (selectedTag.dropdownData != undefined) 
    {
      let selectedIds: any = [];     
      data.map((val: any) => {
        selectedTag.dropdownData.find((item:any) => { 
          if(item.name == val.name)
          {
            selectedIds.push(item.id.toString());
          } 
        }) 
      });
      selectedTag.selectedIds = selectedIds;
    }
    
    filterRequest.filters?.find((val: any) => {
      if (val.fieldName === selectedTag.field && selectedTag.isInitialState == false) {
        val.values = t;
        if(selectedTag.selectedIds != undefined && selectedTag.selectedIds.length != 0)
        {
          val.values = selectedTag.selectedIds;
        }
      }
    });
    
    setFilterRequest({ ...filterRequest });
    setSelectedValue([...selectedValue]);
    if (selectedTag.startErrorMsg != temp) {
      selectedTag.startErrorMsg = temp;
      setSelectedArray([...selectedArray]);
    }
  };
  const handleInputChange = (
    data: any,
    selectedTag: any,
    isStartValue: boolean
  ) => {
    const value = (data.target as HTMLInputElement).value || undefined;
    selectedValue.find((val: any) => {
      if (val.field === selectedTag.field) {
        if (!isStartValue) {
          selectedTag.endValue = data.target.value;
          val.endValue = data.target.value;
        } else {
          val.startValue = data.target.value;
          selectedTag.startValue = data.target.value;
        }
      }
    });
    if (!isStartValue) {
      if (
        value &&
        selectedTag?.startValue &&
        Number(value) <= Number(selectedTag?.startValue)
      ) {
        selectedTag.endErrorMsg =
          "The end value should be greater than start value";
      } else if (value && Number(value) > Number(selectedTag?.startValue)) {
        selectedTag.endErrorMsg = "";
        if(selectedTag?.startValue!== ''){
          selectedTag.startErrorMsg = "";
        }
      }
      if (value === undefined) {
        selectedTag.endErrorMsg = "This field is required";
      }
    } else {
      if (value === undefined) {
        selectedTag.startErrorMsg = "This field is required";
      } else {
        selectedTag.startErrorMsg = "";
      }
      if (
        value &&
        selectedTag?.endValue &&
        Number(value) >= Number(selectedTag?.endValue)
      ) {
        selectedTag.startErrorMsg =
          "The start value should be less than end value";
      } else if (value && Number(value) < Number(selectedTag?.endValue)) {
        selectedTag.startErrorMsg = "";
        if(selectedTag?.endValue !== ''){
          selectedTag.endErrorMsg = "";
        }
      }
    }
    setSelectedValue([...selectedValue]);
    setSelectedArray([...selectedValue]);
    setDirty();
  };

  const handleDateChange = (
    data: any,
    selectedTag: any,
    isStartValue: boolean
  ) => {
    setDirty();
    if (data !== undefined && data !== null) {
      selectedTag && !isStartValue && (selectedTag.endErrorMsg = "");
      selectedTag && isStartValue && (selectedTag.startErrorMsg = "");
    } else {
      !isStartValue && (selectedTag.endErrorMsg = "This field is required");
      isStartValue && (selectedTag.startErrorMsg = "This field is required");
    }
    selectedValue.find((val: any) => {
      if (val.field === selectedTag.field) {
        if (isStartValue) {
          val.startValue = formatDate(data, "yyyy-mm-dd", "-");
          selectedTag.startValue = formatDate(data, "yyyy-mm-dd", "-");
        } else {
          val.endValue = formatDate(data, "yyyy-mm-dd", "-");
          selectedTag.endValue = formatDate(data, "yyyy-mm-dd", "-");
        }
      }
    });
    setSelectedValue([...selectedValue]);
    setSelectedArray([...selectedValue]);
  };
  const FiltersTagComponent = (selectedTag: any, selectedFilters: any) => {
    selectedFilters.push({
      id: "Filter" + selectedTag.name,
      name: selectedTag.name,
      node: (
        <>
          <div id={"Filter" + selectedTag.name} className="Selectedfilter">
            <div className="filtertag">{selectedTag.name}</div>
            <div className="filter-criteria">
              <div className="filter-dropdown">
                <>
                  <TgDropdownTemplate
                    items={selectedTag?.menuitems}
                    value={
                      selectedTag?.condition || selectedTag?.menuitems[0].value
                    }
                    allowUnselect
                    attachToParent={true}
                    onValueSelected={(e: any) => {
                      selectedValue.find((val: any) => {
                        if (val.field === selectedTag.field) {
                          val.condition = e;
                          selectedTag.condition = e;
                        }
                      });
                      setSelectedValue([...selectedValue]);
                      filterRequest.filters.find((val: any) => {
                        if (val.fieldName === selectedTag.field) {
                          val.condition = e;
                          selectedTag.condition = e;
                        }
                      });
                      setFilterRequest({ ...filterRequest });
                      setSelectedValue([...selectedValue]);
                      if (e === "BETWEEN") {
                        dropDownValueSelected.push(selectedTag.field);
                        setDropDownValueSelected([...dropDownValueSelected]);
                      } else {
                        const index = dropDownValueSelected.indexOf(
                          selectedTag.field
                        );
                        if (index >= 0) {
                          const x = dropDownValueSelected.splice(index, 1);
                        }
                        setDropDownValueSelected([...dropDownValueSelected]);
                      }
                      setDirty();
                    }}
                  />
                </>
              </div>

              {selectedTag.type === "Text" ? (
                <div
                  className={
                    (selectedTag && selectedTag.startErrorMsg
                      ? "error-msg-tags-input"
                      : "") + " filter-input"
                  }
                >
                  <TgTagsInput
                    placeHolder={`Search for ` + selectedTag.name + `...`}
                    tags={selectedTag?.subtags}
                    selectedTags={selectedTag?.selectedTags}
                    onTagChange={(e: any) => {
                      handleTagsChange(e, selectedTag);
                    }}
                  ></TgTagsInput>
                  {selectedTag && selectedTag?.startErrorMsg && (
                    <div className="error">{selectedTag?.startErrorMsg}</div>
                  )}
                </div>
              ) : selectedTag.type === "Number" ? (
                <div className="filter-input">
                  <TgInput
                    type="text"
                    placeHolder="Enter value"
                    withoutClear={true}
                    value={selectedTag?.startValue || ""}
                    errorMsg={selectedTag && selectedTag?.startErrorMsg}
                    onKeyDown={allowOnlyNumerics}
                    onInput={(e: any) => {
                      handleInputChange(e, selectedTag, true);
                    }}
                  />
                  {(dropDownValueSelected.includes(selectedTag.field) ||
                    selectedTag.condition === "BETWEEN") && (
                    <TgInput
                      type="text"
                      value={selectedTag?.endValue || ""}
                      placeHolder="Enter value"
                      withoutClear={true}
                      errorMsg={selectedTag && selectedTag?.endErrorMsg}
                      onKeyDown={allowOnlyNumerics}
                      onInput={(e: any) => {
                        handleInputChange(e, selectedTag, false);
                      }}
                    />
                  )}
                </div>
              ) : (
                <div className="d-flex">
                  <div
                    className={
                      (selectedTag && selectedTag.startErrorMsg
                        ? "error-msg-datepicker d-flex flex-column"
                        : "") + " filter-input"
                    }
                  >
                    <TgDatePicker
                      name="startDate"
                      id="startDate"
                      dateFormat={"dd/MM/yyyy"}
                      startYear={APPLICATION_CONSTANTS.START_YEAR}
                      maxDate={
                        selectedTag?.endValue &&
                        new Date(
                          new Date(selectedTag?.endValue).getTime() -
                            24 * 60 * 60 * 1000
                        )
                      }
                      selected={
                        selectedTag?.startValue
                          ? new Date(selectedTag?.startValue)
                          : undefined
                      }
                      placeholderText={"dd/mm/yyyy"}
                      onChange={(e: any) => {
                        handleDateChange(e, selectedTag, true);
                      }}
                      isClearable
                      clearButtonClassName="clear-date"
                    />
                    {selectedTag && selectedTag?.startErrorMsg && (
                      <div className="error">{selectedTag?.startErrorMsg}</div>
                    )}
                  </div>
                  {(dropDownValueSelected.includes(selectedTag.field) ||
                    selectedTag.condition === "BETWEEN") && (
                    <div
                      className={
                        (selectedTag && selectedTag.endErrorMsg
                          ? "error-msg-datepicker d-flex flex-column"
                          : "") + " filter-input ps-3"
                      }
                    >
                      <TgDatePicker
                        name="endDate"
                        dateFormat={"dd/MM/yyyy"}
                        placeholderText={"dd/mm/yyyy"}
                        startYear={APPLICATION_CONSTANTS.START_YEAR}
                        minDate={
                          selectedTag?.startValue &&
                          new Date(
                            new Date(selectedTag?.startValue).getTime() +
                              24 * 60 * 60 * 1000
                          )
                        }
                        selected={
                          selectedTag?.endValue
                            ? new Date(selectedTag?.endValue)
                            : undefined
                        }
                        onChange={(e: any) => {
                          handleDateChange(e, selectedTag, false);
                        }}
                        isClearable
                        clearButtonClassName="clear-date"
                      />
                      {selectedTag && selectedTag?.endErrorMsg && (
                        <div className="error">{selectedTag?.endErrorMsg}</div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      ),
    });
  };
  const updateFilterRequest = () => {
    let selectedFilters: any[] = [];
    const filter: any = [];
    selectedArray.forEach((selectedTag: any, i: any) => {
      selectedValue[i] = selectedTag;
      const index = props.data.findIndex((ind: any) => {
        return ind.name === selectedValue[i].name;
      });
      const data = props.data.map((i: any) => i.data)[index];
      selectedValue[i].subtags = [];
      if (selectedValue[i].type === "Text") {
        selectedValue[i].menuitems = textDropdown;
        if(data) {
          if (selectedValue[i].isInitialState === undefined) {
            selectedValue[i].isInitialState = true;
          } else if (!selectedValue[i].isInitialState) {
            selectedValue[i].isInitialState = false;
          }
          data.forEach((element: any) => {
            selectedValue[i].subtags.push({ name: element });
          });
          let tempSelectedTgas =
            (selectedTag?.values?.length > 0 &&
              selectedTag?.values?.map((e: any) => {
                return { name: e };
              })) ||
            [];
          selectedValue[i].selectedTags = tempSelectedTgas;
        }
      } else {
        selectedValue[i].menuitems = conditionDropdown;
      }
      setSelectedValue(selectedValue);
      FiltersTagComponent(selectedValue && selectedValue[i], selectedFilters);
      filter.push({
        fieldName: selectedValue[i].field,
        condition:
          selectedValue[i].condition || selectedValue[i].menuitems[0].value,
        values:
          selectedValue[i].type !== "Text"
            ? selectedValue[i].condition === "BETWEEN"
              ? [selectedValue[i]?.startValue, selectedValue[i]?.endValue]
              : [selectedValue[i]?.startValue]
            : selectedValue[i].selectedIds ?? selectedValue[i].values,
      });
      filterRequest.filters = [...filter];
      setFilterRequest({ ...filterRequest });
      setFilterTags(selectedFilters);
    });
    if (selectedArray.length === 0) {
      setFilterRequest({});
      setFilterTags([]);
    }
  };

  const clearFilter = () => {
    confirmModal(
      () => {
        setSelectedTags([]);
        setSelectedArray([]);
        selectedArray = [];
        setDropDownValueSelected([]);
        setSelectedValue([]);
        if (props.onFilterMount) {
          props.onFilterMount();
        }
        if (props.rowData) {
          props.rowData({ getDetailedView: props?.view });
        }
        if (props.onFilterDispatch) {
          props.onFilterDispatch("RESET_FILTER_BODY", [])
        }
      },
      () => {},
      "Are you sure to clear the filters? the changes made won't be saved."
    );
  };
  const isValidRequest = (filterBody: any) => {
    let isValid = 0;
    filterBody.map((val: any, index: number) => {
      if (val.condition === "BETWEEN") {
        if(val.values.length == 2){
          if((val.values[0]!== '' && val.values[1]!== '') && Number(val.values[0]) > Number(val.values[1])){
            selectedArray[index].startErrorMsg = "The start value should be less than end value";
            selectedArray[index].endErrorMsg = "The end value should be greater than start value";
            setSelectedArray([...selectedArray]);
            isValid = 1;
          }
        }
        if (
          val.values.length < 2 ||
          val?.values?.includes(null) ||
          val?.values?.includes(undefined) ||
          val?.values?.includes("")
        ) {
          if (
            val?.values &&
            (val.values[0] === undefined ||
              val.values[0] === null ||
              val.values[0] === "")
          ) {
            selectedArray[index].startErrorMsg = "This field is required";
            setSelectedArray([...selectedArray]);
          }
          if (
            val?.values &&
            (val.values[1] === undefined ||
              val.values[1] === null ||
              val.values[1] === "")
          ) {
            selectedArray[index].endErrorMsg = "This field is required";
            setSelectedArray([...selectedArray]);
          }
          isValid = 1;
        }
      } else {
        if (
          val?.values === undefined ||
          val?.values?.length === 0 ||
          !!val?.values?.includes(null) ||
          !!val?.values?.includes(undefined) ||
          !!val?.values?.includes("")
        ) {
          selectedArray[index].startErrorMsg = "This field is required";
          setSelectedArray([...selectedArray]);
          isValid = 1;
        } else {
          selectedArray[index].startErrorMsg = "";
          setSelectedArray([...selectedArray]);
        }
      }
    });

    return isValid;
  };
  const applyFilters = () => {
    if(selectedArray.length===0){
     setFilterFieldError("This field is required");
    }
    else{
      setFilterFieldError("");
    selectedArray?.map((obj: any) => {
      if (obj.type === "Text") {
        obj.isInitialState = false;
      }
    });
    setSelectedArray([...selectedArray]);
    setFilterRequest({ ...filterRequest });
    const isValid = isValidRequest(filterRequest.filters);
    if (isValid === 0) {
      let tempFilterRequest: any = { ...filterRequest };
      tempFilterRequest.getDetailedView = props?.view;
      setFilterRequest({ ...tempFilterRequest });
      if (props.rowData) {
        props.rowData(tempFilterRequest);
      }
      props.onModal(false);
      if (props.onFilterDispatch) {
        props.onFilterDispatch("UPDATE_FILTER_BODY", [...selectedArray])
      }
      return isShowModal(false);
    }
  }
  };
  const setDirty = () => {
    props?.$dirty && !props?.$dirty.getValue() && props?.$dirty.next(true);
  };
  const closeModal = () => {
    if(props?.onCancel){
    props?.onCancel();
    }
  };
  useEffect(() => {
    setSelectedValue([]);
    setSelectedArray([]);
    sortingTags(props.data)
    setTags(props.data);
  }, [props.data]);
  useEffect(() => {
    setSelectedArray(structuredClone(storefilterBody));
    setSelectedTags(structuredClone(storefilterBody));
  }, [storefilterBody]);
  useEffect(() => {
    updateFilterRequest();
  }, [selectedArray, dropDownValueSelected]);
  return (
    <>
      <Modal.Body>
        <div className={(filterFieldError? "error-msg-tags-input": "") + " filter"}>
          <TgTagsInput
            selectedTags={selectedTags}
            required={true}
            noMatchText="No Match Found"
            tags={tags}
            label="Filter Fields"
            onTagChange={(e: any) => {
              handleSelectedTagsChange(e);
            }}
          ></TgTagsInput>
          {filterFieldError &&(
              <div className="error">{filterFieldError}</div>
          )}
        </div>
        <div id="filtersOutlet">
          {filterTags &&
            filterTags.length > 0 &&
            filterTags.map((tag: any) => <>{tag.node}</>)}
        </div>
        {filterTags && filterTags.length > 0 && (
          <div className="clear-filter" onClick={clearFilter}>
            Clear All
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="tg-button btn btn-primary modal-cancel-button"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          className="tg-button btn btn-primary"
          onClick={() => {
            applyFilters();
          }}
        >
          Update
        </button>
      </Modal.Footer>
    </>
  );
}
export default FilterBody;
