import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import UnAuthorized from "../../views/components/UnAuthorized/UnAuthorized";
import Login from "../../views/components/Login/login";
import PrivacyPage from "../../views/components/Terms/PrivacyPage";
import TermsOfUsePage from "../../views/components/Terms/TermsOfUsePage";
function UnAuthenticatedRoutesConfig() {
  return (
    <Routes>
      <Route path="/unauthorized" element={<UnAuthorized />} />
      <Route path="/login" element={<Login />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/termsofuse" element={<TermsOfUsePage />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
}
export default UnAuthenticatedRoutesConfig;
