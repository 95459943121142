const calculateDate = (d: number) => {
    const calculatedDate = new Date();
    calculatedDate.setDate(calculatedDate.getDate() - d);
    return calculatedDate;
  };

export const notificationRanges = [
    {
      key: 1,
      label: "All",
      value: null,
    },
    {
      key: 2,
      label: "Last 3 Days",
      value: calculateDate(3),
    },
    {
      key: 3,
      label: "Last 7 Days",
      value: calculateDate(7),
    },
    {
      key: 4,
      label: "Last 15 Days",
      value: calculateDate(15),
    },
    {
      key: 5,
      label: "Last 30 Days",
      value: calculateDate(30),
    },
    {
      key: 6,
      label: "Last 90 Days",
      value: calculateDate(90),
    },
  ];
 export function getCustomTime(date: Date) {
    let hours = date.getHours();
    let minutes =
      date.getMinutes() / 10 >= 1 ? date.getMinutes() : "0" + date.getMinutes();
    let seconds =
      date.getSeconds() / 10 >= 1 ? date.getSeconds() : "0" + date.getSeconds();

    const amPm = hours >= 12 ? "PM" : "AM";
    // convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12;

    // combine all parts into a time string
    const timeString = hours + ":" + minutes + ":" + seconds + " " + amPm;

    return timeString;
  }

  export function getDisplayDate(dateString: string) {
    var currentDate = new Date();
    var date = new Date(dateString + "Z");
    if (date.getDate() == currentDate.getDate()) {
      return "Today at " + getCustomTime(date);
    } else if (date.getDate() == currentDate.getDate() - 1) {
      return "Yesterday at " + getCustomTime(date);
    } else {
      return date.toLocaleDateString() + " " + getCustomTime(date);
    }
  }

  export function getDescription(
    formatString: string,
    placeholderValues: NotificationResponse
  ) {
    let formattedEvent = formatString;
    Object.entries(placeholderValues).forEach(([key, value]) => {
      const placeholder = new RegExp(`\\[${key.toUpperCase()}\\]`, "g");
      const wrappedValue = `<span class="notification-log">${value ? value : "NA"}</span>`;
      formattedEvent = formattedEvent?.replace(
        new RegExp(placeholder, "g"),
        wrappedValue
      );
    });
    return formattedEvent;
  }



  export class NotificationResponse {
    notificationId?: number;
    moduleId?: number;
    moduleName: string='';
    entityId?: number;
    entityName?: string;
    initialLog?: boolean;
    oldValue?: string;
    newValue?: string;
    fieldName?: string;
    formatId?: number;
    formatDescription: string='';
    createdBy?: number;
    createdByName: string='';
    createdDate: string='';
    isRead?: boolean;
}

export interface ToolbarNotification  {
  notificationId?: number;
  moduleId?: number;
  moduleName: string;
  entityId?: number;
  entityName?: string;
  initialLog?: boolean;
  oldValue?: string;
  newValue?: string;
  fieldName?: string;
  formatId?: number;
  formatDescription?: string;
  description:string;
  createdBy:string;
  createdByName: string;
  createdDate: string;
  isRead?: boolean;
}
  
