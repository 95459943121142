import React from 'react';
import './UnauthorizedPage.css';

const UnauthorizedPage = () => {
  return (
    <div className="unauthorized-page">
      <div className="content">
        <h2>Access Denied</h2>
        <p>You do not have permission to view this page.</p>
        <p>
          Please contact your administrator for assistance
          {/* <Link to="/">home page</Link>. */}
        </p>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
