import React, { useEffect, useState } from "react";
import { TgGridMUI } from "@tg/react-ui-kit";
import "./Grid.scss";

function Grid(props: any) {
  let isPagination = props.isPagination == false ? props.isPagination : true;
  const [searchFieldName, setSearchFieldName] = useState<string>("");
  useEffect(() => {
    if (window.innerWidth > 768) setSearchFieldName(props?.searchFieldName);
    window.addEventListener("resize", handleSearchFieldName);
    return () => {
      window.removeEventListener("resize", handleSearchFieldName);
    };
  }, []);

  const handleSearchFieldName = () => {
    if (window.innerWidth < 768) setSearchFieldName("");
    else setSearchFieldName(props?.searchFieldName);
  };

  return (
    <div className="grid-wrapper" style={{ height: props?.height || "620px" }}>
      <TgGridMUI
        showPagination = {isPagination}
        // serverSidePagination
        pageSizeList={ isPagination ? [10, 20, 30, 40, 50] : [3, 6, 9, 12, ]} 
        showTotalRowCount
        {...props}
        defaultColDef={isPagination && {
          width: "120px",
          sortable: true,
          ...props?.defaultColDef,
        }}
        searchFieldName={isPagination && searchFieldName}
        onSelectionChanged = {(rows : any[]) => {
          if(props.setList && isPagination){
            props.setList(rows);
          }
        }}
      />
    </div>
  );
}


  

export default Grid;


