import Employee from "../../models/api/responses/Employee";

const fetchProjectsByEmployeeIdData:any={
    data: [],
    error: [],
    status : 'idle',
};
const employeeInitialValues:any={
  data:new Employee(),
  error:"",
  status : 'idle' ,
}
const initialValues: any = {
  data: [],
  error: "",
  status:"idle",
};
export const fetchProjectsByEmployeeIdReducer = (
    state = fetchProjectsByEmployeeIdData,
    action: any
  ) => {
    switch (action.type) {
      case "FETCH_EMPLOYEE_PROJECTS":
        return {
          data: action.payload,
          error: "",
          status : action.status,
        };
      case "FETCH_EMPLOYEE_PROJECTS_LOADING":
        return {
          data: action.payload,
          error: "",
          status:action.status,
        };
      case "FETCH_EMPLOYEE_PROJECTS_ERROR":
        return {
          data: [],
          error: action.payload,
          status: action.status,
        };
      default:
        return state;
    }
  };

  export const employeeByIdReducer = (
    state = employeeInitialValues,
    action: any
  ) => {
    switch (action.type) {
      case "FETCH_EMPLOYEE_BY_EMPLOYEE_ID":
        return {
          data: action.payload,
          error: "",
          status: action.status,
        };
      case "FETCH_EMPLOYEE_BY_EMPLOYEE_ID_LOADING":
        return {
          data: action.payload,
          error: "",
          status: action.status,
        };
      case "FETCH_EMPLOYEE_BY_EMPLOYEE_ID_ERROR":
        return {
          data: new Employee(),
          error: action.payload,
          status: action.status,
        };
      default:
        return state;
    }
  };


  export const employeeFilterBodyReducer = (
    state = [],
    action : any
  ) => {
    switch(action.type) {
      case "UPDATE_EMPLOYEE_FILTER_BODY":
        return [...action.payload];
      case "RESET_EMPLOYEE_FILTER_BODY":
        return [];
      default:
        return state;
    }
  }
