import { API_SLUG_CONSTANTS } from "../../constants/apiConstants";
import { fetchData, returnPromise } from "../../helpers/fetchData";
const ADD_USER_DETAILS = "ADD_USER_DETAILS";
const GET_PAGE_DETAILS = "GET_PAGE_DETAILS";
const SET_PAGE_DETAILS = "SET_PAGE_DETAILS";
const GET_ALL_PRACTICES = "GET_ALL_PRACTICES";
const API_URL = process.env.REACT_APP_API_URL || "http://3.111.95.51:8080/api";
const FETCH_USER_NOTIFICATIONS = "FETCH_USER_NOTIFICATIONS";

export const getPageDetailsAction = () => {
  return {
    type: GET_PAGE_DETAILS,
  };
};

export const setPageDetailsAction = (obj: any) => {
  return {
    type: SET_PAGE_DETAILS,
    payload: obj,
  };
};

export const addUserDetailsAction = (obj:any) => {
  return {
    type: ADD_USER_DETAILS,
    payload: obj,
  };
};

export const authorizeToken = (token: any) => {
  var authorizeTokenRequest = new Request(
    API_URL + `${API_SLUG_CONSTANTS.AUTHORIZE_TOKEN}${token}`,
    {
      method: "POST",
    }
  );
  return returnPromise(authorizeTokenRequest);
};

export const getPermissions = () => {
  var permissionsRequest = new Request(
    API_URL + `${API_SLUG_CONSTANTS.GET_USER_PERMISSIONS}`,
    {
      method: "GET",
    }
  );
  return returnPromise(permissionsRequest);
};

export const getNotifications = (body: any = null) => {
  var requestNotifications = new Request(
    API_URL + API_SLUG_CONSTANTS.GET_USER_NOTIFICATIONS,
    {
      method : "POST",
      body : body == null ? JSON.stringify({}) : JSON.stringify(body),
    }
  );
  return returnPromise(requestNotifications);
};

export const getUserNotificationss = (body: any = null) => {
  var requestNotifications = new Request(
    API_URL + API_SLUG_CONSTANTS.GET_USER_NOTIFICATIONS,
    {
      method : "POST",
      body : body == null ? JSON.stringify({}) : JSON.stringify(body),
    }
  );
  return fetchData(requestNotifications, FETCH_USER_NOTIFICATIONS , JSON.stringify(requestNotifications));
};

export const updateUserNotificationStatus = (body: any) => {
  var updateUserNotificationStatusRequest = new Request(
    API_URL + API_SLUG_CONSTANTS.UPDATE_USER_NOTIFICATION_STATUS,
    {
      method : "PUT",
      body: body == null ? JSON.stringify({}) : JSON.stringify(body),
    }
  );
  return returnPromise(updateUserNotificationStatusRequest);
};

export const getAllPractices = () =>
{
  var getAllPractices = new Request(API_URL + API_SLUG_CONSTANTS.GET_ALL_PRACTICES,
    {
      method:"GET"
    }
    );
    return fetchData(getAllPractices,"GET_ALL_PRACTICES");
}

export const createResourceRequest = (body:any)=>
{
  var request = new Request(API_URL + API_SLUG_CONSTANTS.CREATE_RESOURCE_REQUEST,
  {
    method:'POST',
    body : body == null ? JSON.stringify({}) : JSON.stringify(body),
  }
  );
  return returnPromise(request);
}