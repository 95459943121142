export default class Employee{
    employeeName?: string;
    employeeId?: number;
    employeeNumber?:number;
    designation?: string;
    associatedPractice?: string;
    emailId?: string;
    mobileNumber?: string;
    totalActiveProjects?: number;
    projectId?: number;
    projectName?: string;
    projectManagerId?: number;
    projectManager?: string;
    projectStartDate?: string;
    expectedEndDate?: string;
    employeeRollOffDate?: string;
    projectCreatedDate?:string;
    billingHours?: number;
    utilizationPercentage?: number;
    billabilityStatus?: string;
    billabilityStatusId?: number;
    employeeStatus?: string;
    employeeStatusId?: number;
    projectOverview?: string;
    talentPool?: string;
    coEHours?:number;
    trainingHours?:number;
    isEmployeeActive?:boolean;
    otherProjects?: string;

}
