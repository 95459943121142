import { Module, Action } from './AuthContext';

export type PermissionsConstants = { module: Module; action: Action };

export const VALIDATION_DATE : string = "25";

export const ACCOUNT_READ: PermissionsConstants = { module: 'ACCOUNT', action: 'READ' };
export const ACCOUNT_ADD: PermissionsConstants = { module: 'ACCOUNT', action: 'ADD' };
export const ACCOUNT_EDIT: PermissionsConstants = { module: 'ACCOUNT', action: 'EDIT' };
export const ACCOUNT_EXPORT: PermissionsConstants = { module: 'ACCOUNT', action: 'EXPORT' };
export const ACCOUNT_REQUEST: PermissionsConstants = { module: 'ACCOUNT', action: 'REQUEST' };
export const ACCOUNT_BYPASS: PermissionsConstants = { module: 'ACCOUNT', action: 'BYPASS' };

export const PROJECT_READ: PermissionsConstants = { module: 'PROJECT', action: 'READ' };
export const PROJECT_ADD: PermissionsConstants = { module: 'PROJECT', action: 'ADD' };
export const PROJECT_EDIT: PermissionsConstants = { module: 'PROJECT', action: 'EDIT' };
export const PROJECT_EXPORT: PermissionsConstants = { module: 'PROJECT', action: 'EXPORT' };
export const PROJECT_REQUEST: PermissionsConstants = { module: 'PROJECT', action: 'REQUEST' };
export const PROJECT_BYPASS: PermissionsConstants = { module: 'PROJECT', action: 'BYPASS' };

export const EMPLOYEES_READ: PermissionsConstants = { module: 'EMPLOYEES', action: 'READ' };
export const EMPLOYEES_ADD: PermissionsConstants = { module: 'EMPLOYEES', action: 'ADD' };
export const EMPLOYEES_EDIT: PermissionsConstants = { module: 'EMPLOYEES', action: 'EDIT' };
export const EMPLOYEES_EXPORT: PermissionsConstants = { module: 'EMPLOYEES', action: 'EXPORT' };
export const EMPLOYEES_REQUEST: PermissionsConstants = { module: 'EMPLOYEES', action: 'REQUEST' };
export const EMPLOYEES_BYPASS: PermissionsConstants = { module: 'EMPLOYEES', action: 'BYPASS' };

export const CONTACT_READ: PermissionsConstants = { module: 'CONTACT', action: 'READ' };
export const CONTACT_ADD: PermissionsConstants = { module: 'CONTACT', action: 'ADD' };
export const CONTACT_EDIT: PermissionsConstants = { module: 'CONTACT', action: 'EDIT' };
export const CONTACT_EXPORT: PermissionsConstants = { module: 'CONTACT', action: 'EXPORT' };
export const CONTACT_REQUEST: PermissionsConstants = { module: 'CONTACT', action: 'REQUEST' };
export const CONTACT_BYPASS: PermissionsConstants = { module: 'CONTACT', action: 'BYPASS' };

export const TIMELINES_READ: PermissionsConstants = { module: 'TIMELINES', action: 'READ' };
export const TIMELINES_ADD: PermissionsConstants = { module: 'TIMELINES', action: 'ADD' };
export const TIMELINES_EDIT: PermissionsConstants = { module: 'TIMELINES', action: 'EDIT' };
export const TIMELINES_EXPORT: PermissionsConstants = { module: 'TIMELINES', action: 'EXPORT' };
export const TIMELINES_REQUEST: PermissionsConstants = { module: 'TIMELINES', action: 'REQUEST' };
export const TIMELINES_BYPASS: PermissionsConstants = { module: 'TIMELINES', action: 'BYPASS' };

export const RESOURCES_READ: PermissionsConstants = { module: 'RESOURCES', action: 'READ' };
export const RESOURCES_ADD: PermissionsConstants = { module: 'RESOURCES', action: 'ADD' };
export const RESOURCES_EDIT: PermissionsConstants = { module: 'RESOURCES', action: 'EDIT' };
export const RESOURCES_EXPORT: PermissionsConstants = { module: 'RESOURCES', action: 'EXPORT' };
export const RESOURCES_REQUEST: PermissionsConstants = { module: 'RESOURCES', action: 'REQUEST' };
export const RESOURCES_BYPASS: PermissionsConstants = { module: 'RESOURCES', action: 'BYPASS' };

export const SKILL_TRACKING_READ: PermissionsConstants = { module: 'SKILL_TRACKING', action: 'READ' };
export const SKILL_TRACKING_ADD: PermissionsConstants = { module: 'SKILL_TRACKING', action: 'ADD' };
export const SKILL_TRACKING_EDIT: PermissionsConstants = { module: 'SKILL_TRACKING', action: 'EDIT' };
export const SKILL_TRACKING_EXPORT: PermissionsConstants = { module: 'SKILL_TRACKING', action: 'EXPORT' };
export const SKILL_TRACKING_REQUEST: PermissionsConstants = { module: 'SKILL_TRACKING', action: 'REQUEST' };
export const SKILL_TRACKING_BYPASS: PermissionsConstants = { module: 'SKILL_TRACKING', action: 'BYPASS' };

export const DOCUMENT_READ: PermissionsConstants = { module: 'DOCUMENTS', action: 'READ' };
export const DOCUMENT_ADD: PermissionsConstants = { module: 'DOCUMENTS', action: 'ADD' };
export const DOCUMENT_EDIT: PermissionsConstants = { module:'DOCUMENTS', action: 'EDIT' };
export const DOCUMENT_DOWNLOAD: PermissionsConstants = { module: 'DOCUMENTS', action: 'EXPORT' };
export const DOCUMENT_BYPASS: PermissionsConstants = { module: 'DOCUMENTS', action: 'BYPASS' };

export const PMO_DOCUMENT_READ: PermissionsConstants = { module: 'PMO_DOCUMENTS', action:'READ'};
export const PMO_DOCUMENT_ADD: PermissionsConstants = { module: 'PMO_DOCUMENTS', action:'ADD'};
export const PMO_DOCUMENT_EDIT: PermissionsConstants = { module: 'PMO_DOCUMENTS', action:'EDIT'};
export const PMO_DOCUMENT_EXPORT: PermissionsConstants = { module: 'PMO_DOCUMENTS', action:'EXPORT'};
export const PMO_DOCUMENT_BYPASS: PermissionsConstants = { module: 'PMO_DOCUMENTS', action:'BYPASS'};

export const MONTHLY_REPORTS_READ: PermissionsConstants = { module: 'MONTHLY_REPORTS', action:'READ'};
export const MONTHLY_REPORTS_ADD: PermissionsConstants = { module: 'MONTHLY_REPORTS', action:'ADD'};
export const MONTHLY_REPORTS_EDIT: PermissionsConstants = { module: 'MONTHLY_REPORTS', action:'EDIT'};
export const MONTHLY_REPORTS_EXPORT: PermissionsConstants = { module: 'MONTHLY_REPORTS', action:'EXPORT'};
export const MONTHLY_REPORTS_BYPASS: PermissionsConstants = { module: 'MONTHLY_REPORTS', action:'BYPASS'};

export const KT_DOCUMENT_READ: PermissionsConstants = { module: 'PROJECT_DOCUMENTS', action:'READ'};
export const KT_DOCUMENT_ADD: PermissionsConstants = { module: 'PROJECT_DOCUMENTS', action:'ADD'};
export const KT_DOCUMENT_EDIT: PermissionsConstants = { module: 'PROJECT_DOCUMENTS', action:'EDIT'};
export const KT_DOCUMENT_EXPORT: PermissionsConstants = { module: 'PROJECT_DOCUMENTS', action:'EXPORT'};
export const KT_DOCUMENT_BYPASS: PermissionsConstants = { module: 'PROJECT_DOCUMENTS', action:'BYPASS'};

export const RESOURCE_REQUEST_READ: PermissionsConstants = { module: 'RESOURCE_REQUEST', action:'READ'};
export const RESOURCE_REQUEST_ADD: PermissionsConstants = { module: 'RESOURCE_REQUEST', action:'ADD'};
export const RESOURCE_REQUEST_EDIT: PermissionsConstants = { module: 'RESOURCE_REQUEST', action:'EDIT'};
export const RESOURCE_REQUEST_EXPORT: PermissionsConstants = { module: 'RESOURCE_REQUEST', action:'EXPORT'};
export const RESOURCE_REQUEST_ASSIGN: PermissionsConstants = { module: 'RESOURCE_REQUEST', action:'ASSIGN'};
export const RESOURCE_REQUEST_RECOMMEND: PermissionsConstants = { module: 'RESOURCE_REQUEST', action:'RECOMMEND'};
export const RESOURCE_REQUEST_BYPASS: PermissionsConstants = { module: 'RESOURCE_REQUEST', action:'BYPASS'};

export const ASSIGNED_REQUESTS_READ: PermissionsConstants= {module: 'ASSIGNED_REQUESTS', action: 'READ'}

export const EMPLOYEE_ACTIVITY_READ: PermissionsConstants = { module: 'EMPLOYEE_ACTIVITY', action: 'READ' };
export const EMPLOYEE_ACTIVITY_ADD: PermissionsConstants = { module: 'EMPLOYEE_ACTIVITY', action: 'ADD' };
export const EMPLOYEE_ACTIVITY_EDIT: PermissionsConstants = { module: 'EMPLOYEE_ACTIVITY', action: 'EDIT' };
export const EMPLOYEE_ACTIVITY_BYPASS: PermissionsConstants = { module: 'EMPLOYEE_ACTIVITY', action: 'BYPASS' };
