import {
  associationTypeReducer,
  contactTypeReducer,
  searchProjectNameReducer,
  contactByIdReducer,
  createAccountContactReducer,
  updateContactReducer,
  accountByIdReducer,
  accountStatusReducer,
  contactStatusReducer,
  accountTypeReducer,
  countryReducer,
  shiftTypesReducer,
  shadowTypesReducer,
  organizationSizeReducer,
  fetchAccountsReducer,
  createAccountReducer,
  fetchContactsReducer,
  setAccountIdReducer,
  fetchRolesReducer,
  fetchEmployeesWithStatusReducer,
  fetchAllocationCategoryReducer,
  fetchResourcesReducer,
  fetchProjectsByAccountIdReducer,
  fetchProjectsByProjectNameReducer,
  fetchEmployeeByEmployeeNameReducer,
  addEmployeeToProjectReducer,
  updateAccountResourceReducer,
  editAccountResourceReducer,
  fetchResourceByProjectIdReducer,
  accountFilterBodyReducer,
  activityFilterBodyReducer,
  fetchTimelineFieldReducer,
  fetchEmployeeDocumentsReducer,
  businessDetailsReducer
} from "./Reducer/AccountReducer";
import {
  fetchProjectsReducer, projectCategoryReducer, projectStatusReducer,projectPlatformReducer, projectTimeZoneReducer, projectTypeReducer, fetchAllEmployeesReducer, projectFilterBodyReducer, futureProjectionExtraDetailsReducer, futureProjectionStatusDetailsReducer, futureProjectionStatusReducer,  projectNamesReducer, skillsNamesReducer , createProjectContactReducer, ProjectByIdReducer }
from "./Reducer/ProjectReducer";
import { fetchProjectsByEmployeeIdReducer, employeeByIdReducer, employeeFilterBodyReducer} from "../redux/Reducer/EmployeeReducer"
import { pageDetailsReducer, userReducer, isLogoutSessionReducer, getAllPracticesReducer, fetchUserNotificationReducer } from "./Reducer/ApplicationReducer";
import { fetchResourceResourceRequestsReducer, resourceRequestFilterBodyReducer, fetchResourceByRequestIdReducer, fetchEmployeeProjectMapDetailsByResourceRequestReducer, fetchRecommendedAndAssignedResourcesByRequestIdReducer, recommendedAndAssignedResourceFilterBodyReducer } from "./Reducer/ResourceRequestReducer";
import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
const rootReducer = combineReducers({
  user: userReducer,
  pageDetails: pageDetailsReducer,
  fetchUserNotification:fetchUserNotificationReducer,
  organizationSize: organizationSizeReducer,
  accountType: accountTypeReducer,
  accountStatus: accountStatusReducer,
  contactStatus: contactStatusReducer,
  country: countryReducer,
  accountById: accountByIdReducer,
  contactById: contactByIdReducer,
  fetchAccounts: fetchAccountsReducer,
  fetchContacts: fetchContactsReducer,
  createAccount: createAccountReducer,
  accountId: setAccountIdReducer,
  associationType: associationTypeReducer,
  contactType: contactTypeReducer,
  projectNameBySearch: searchProjectNameReducer,
  createAccountContact: createAccountContactReducer,
  updateAccountContact: updateContactReducer,
  roles: fetchRolesReducer,
  employeesWithStatus: fetchEmployeesWithStatusReducer,
  allocationCategory: fetchAllocationCategoryReducer,
  resourcesByAccountId: fetchResourcesReducer,
  projectsByAccountId: fetchProjectsByAccountIdReducer,
  resourceByProjectId: fetchResourceByProjectIdReducer,
  fetchProjectByProjectName: fetchProjectsByProjectNameReducer,
  fetchEmployeeByEmployeeName: fetchEmployeeByEmployeeNameReducer,
  addEmployeeToProject: addEmployeeToProjectReducer,
  updateAccountResource: updateAccountResourceReducer,
  editAccountResource: editAccountResourceReducer,
  projectById: ProjectByIdReducer,
  createProjectContact: createProjectContactReducer,
  fetchProjects: fetchProjectsReducer,
  projectStatus: projectStatusReducer,
  projectType: projectTypeReducer,
  projectTimeZone : projectTimeZoneReducer,
  projectPlatform : projectPlatformReducer,
  projectCategory: projectCategoryReducer,
  projectNames:projectNamesReducer,
  skillNames: skillsNamesReducer,
  businessDetails:businessDetailsReducer,
  futureProjectionStatus:futureProjectionStatusReducer,
  futureProjectionStatusDetails:futureProjectionStatusDetailsReducer,
  futureProjectionExtraDetails:futureProjectionExtraDetailsReducer,
  shiftTypes: shiftTypesReducer,
  shadowTypes: shadowTypesReducer,
  fetchAllEmployees: fetchAllEmployeesReducer,
  fetchProjectsByEmployeeId: fetchProjectsByEmployeeIdReducer,
  employeeById: employeeByIdReducer,
  isLogout :  isLogoutSessionReducer,
  employeeFilterBody : employeeFilterBodyReducer,
  accountFilterBody: accountFilterBodyReducer,
  activityFilterBody: activityFilterBodyReducer,
  projectFilterBody: projectFilterBodyReducer,
  timeLineFields: fetchTimelineFieldReducer,
  employeeDocuments:fetchEmployeeDocumentsReducer,
  getAllPractices:getAllPracticesReducer,
  fetchResourceRequest:fetchResourceResourceRequestsReducer,
  resourceRequestFilterBody:resourceRequestFilterBodyReducer,
  fetchResourceRequestByRequestId:fetchResourceByRequestIdReducer,
  fetchEmployeeProjectMapDetailsByResourceRequest: fetchEmployeeProjectMapDetailsByResourceRequestReducer,
  fetchRecommendedAndAssignedResourcesByRequestId: fetchRecommendedAndAssignedResourcesByRequestIdReducer,
  recommendedAndAssignedResourceFilterBody: recommendedAndAssignedResourceFilterBodyReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;

export type AppDispatch = typeof store.dispatch;
