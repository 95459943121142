import { modalService } from "@tg/react-ui-kit";
import React from "react";

export function confirmModal(
  onYes: Function = () => {},
  onNo: Function = () => {},
  Message: string = "",
  title: string = "Are you sure?",
) {
  modalService.open({
    element: <>{Message}</>,
    title: title,
    maxWidth: "400px",
    onClose:()=>{
      onNo();
      modalService.close()
    },
    actionButtons: [
      {
        class: "btn btn-primary modal-cancel-button",
        text: "No",
        action: () => {
          onNo();
          modalService.close();
        },
      },
      {
        class: "btn btn-primary",
        text: "Yes",
        action: () => {
          onYes();
          modalService.close();
        },
      },
    ],
  });
}
