export const APPLICATION_CONSTANTS = { START_YEAR:2019,
END_YEAR:2080,
REQUIRED_FILED:"This field is required"};

export const RESOURCE_FORM_VALIDATIONS = {EMPLOYEE_SHADOW_LEAD_NAME_VALIDATION:"Employee, Shadow and Lead name cannot be same",
EMPLOYEE_SHADOW_NAME_VALIDATION:"Employee and Shadow name cannot be same.",
EMPLOYEE_LEAD_NAME_VALIDATION:"Employee and Lead name cannot be same",
SHADOW_LEAD_NAME_VALIDATION:"Shadow and Lead name cannot be same",
UTILIZATION_PERCENTAGE_EXCEEDED:"Utilization Percentage exceeded 100%",

};
export const CONTACT_FORM_VALIDATIONS = {
  CONTACT_END_DATE_ACCOUNT_END_DATE_EXPIRED : "Contact End Date is greater than Account End Date",
  CONTACT_END_DATE_PROJECT_END_DATE_EXPIRED : "Contact End Date is greater than Project End Date",
  CONTACT_TYPE_REQUIRED : "Contact Type is required",
  FULL_NAME_REQUIRED : "Full Name is required",
  DEPARTMENT_TYPE_REQUIRED : "Department Type is required",
  JOB_TITLE_REQUIRED : "Job Title is required",
  PRIMARY_EMAIL_REQUIRED : "Primary Email is required",
  VALID_EMAIL : "Enter valid Email",
  SPECIAL_CHARACTERS_VALIDATION :"Should not contain numbers or special characters",
  STATUS_REQUIRED : "Status is required",
  END_DATE_GREATER_THAN_START_DATE : "end date should be greater than startdate",

}

export const ACCOUNT_FORM_VALIDATIONS = {
  ACCOUNT_ACTUAL_END_DATE_GREATER_THAN_START_DATE:
    "Actual End Date should be greater than Start Date",
    ACCOUNT_NAME_REQUIRED :"Account Name is required",
    ACCOUNT_CODE_REQUIRED :"Account Code is required",
    START_DATE_REQUIRED :"Start Date is required",
    VALID_WEBSITE_URL:"Enter a valid Website URL",
    VALID_INSTAGRAM_URL:"Enter a valid Instagram URL",
    VALID_FACEBOOK_URL:"Enter a valid Facebook URL",
    VALID_TWITTER_URL:"Enter a valid Twitter URL",
    VALID_LINKEDIN_URL:"Enter a valid LinkedIn URL",
    
    ASSOCIATION_TYPE_REQUIRED : "Association Type is required",
    ACCOUNT_OWNERS_REQUIRED :"Account Owners field is required",
    ACCOUNT_NAME_MINIMUM_CHARACTERS_VALIDATION : "Account Name should have minimum 3 characters",
    ACCOUNT_CODE_MINIMUM_CHARACTERS_VALIDATION : "Account Code should have minimum 3 characters",
    PIN_CODE_MINIMUM_AND_MAXIMUM_CHARACTERS_VALIDATION : "Pincode should have minimum 4 and maximum 9 digits",
};

export const PROJECT_FORM_VALIDATIONS = {
  PROJECT_END_DATE_GREATER_THAN_START_DATE:
    "End Date should be greater than Start Date",
};

export const POPUP_CONFIRMATION_MESSAGE = {
  CONFIRMATION_MESSAGE : "Are you sure to close the form? the changes made won't be saved."
}

export const PRIMARY_PROJECT_CONFIRMATION = {
  PRIMARY_PROJECT_ALREADY_EXISTS : "The employee is already added as primary in a project. Do you wish to change?"
}

export const PRIMARY_FUTURE_PROJECTION_CONFIRMATION = {
  PRIMARY_FUTURE_PROJECTION_ALREADY_EXISTS : "The Projection is already added as primary for Employee. Do you wish to change?"
}

export const PROJECT_VALIDATION_MESSAGES = {
  PROJECT_CODE_MINIMUM_CHARACTERS_VALIDATION : "Project Code should have minimum 3 characters",
  PROJECT_NAME_MINIMUM_CHARACTERS_VALIDATION : "Project Name should have minimum 3 characters",
  TOOL_NAME_MINIMUM_CHARACTERS_VALIDATION : "Tool Name should have minimum 3 characters",
  PLATFORM_MINIMUM_CHARACTERS_VALIDATION : "Platform should have minimum 3 characters",
  TAGS_MINIMUM_CHARACTERS_VALIDATION : "Tags should have minimum 3 characters",
  TOOL_NAME_MAXIMUM_CHARACTERS_VALIDATION : "Tool Name should not exceed 350 characters",
  TAGS_MAXIMUM_CHARACTERS_VALIDATION : "Tags should not exceed 15 characters",
  PLATFORM_MAXIMUM_CHARACTERS_VALIDATION : "Platform should not exceed 75 characters",
  PROJECT_NAME_IS_REQUIRED : "Project Name is required",
  TOOL_NAME_IS_REQUIRED : "Tool Name is required",
  PROJECT_CODE_IS_REQUIRED : "Project Code is required",
  PLATFORM_IS_REQUIRED : "Platform is required",
  EXPECTED_END_DATE_GREATER_THAN_START_DATE: "Expected End Date should be greater than Start Date"
}

export const EMPLOYEE_SKILL_TRACKING_SKILL_MENU = {
  TRAINED: 'Trained',
  BEGINNER: 'Beginner',
  INTERMEDIATE: 'Intermediate',
  EXPERT: 'Expert'
}

export const EMPLOYEE_SKILL_TRACKING_CERTIFICATION_MENU = {
  DONE: 'Done',
  IN_PIPELINE:'In Pipeline'
}