export const allowOnlyNumerics = (e: any) => {
    if (
        !(
        (e.key.charCodeAt(0) >= 48 && e.key.charCodeAt(0)  <= 57) ||
        e.keyCode === 8 || e.key.charCodeAt(0) ===84
        )
    ) {
        e.preventDefault();
    }
}
export const allowDecimals=(e: any) => {
    if (
        !(
        (e.key.charCodeAt(0) >= 48 && e.key.charCodeAt(0)  <= 57) ||
        e.keyCode === 8 || e.key.charCodeAt(0) === 46
        )
    ) {
        e.preventDefault();
    }
}