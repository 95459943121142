const userInitialValue = {
  userName: "Dileep Ravula",
};
  
const pageDetails = {
  pageTitle: "Buzz",
  breadCrumb: [],
};

const isLogoutSessionValue = {
  isLogout : false,
}
  
export const userReducer = (state = userInitialValue, action: any) => {
  switch (action.type) {
    case "ADD_USER_DETAILS":
      return {
        ...state,
        userName: action.payload.userName || state.userName,
      };
    default:
      return state;
  }
};

export const pageDetailsReducer = (state = pageDetails, action: any) => {
  switch (action.type) {
    case "GET_PAGE_DETAILS":
      return state;
    case "SET_PAGE_DETAILS":
      return {
        ...state,
        pageTitle: action.payload.pageTitle || "Buzz",
        breadCrumb: action.payload.breadCrumb || [],
      };
    default:
      return state;
  }
};

export const isLogoutSessionReducer = (state = isLogoutSessionValue , action: any) => {
  switch (action.type) {
    case "RESET_LOGOUT_STATE" :
      return isLogoutSessionValue
    case "LOGOUT_USER" :
      return {
        isLogout : true,
      };
    default:
      return state;
  }
}

export const fetchUserNotificationReducer = (
  state = fetchUserNotificationsData,
  action: any
) => {
  switch (action.type) {
    case "FETCH_USER_NOTIFICATIONS":
      return {
        ...state,
        data: [...action.payload, ...state.data],
        error: "",
        status: action.status,
      };
    case "FETCH_USER_NOTIFICATIONS_LOADING":
      return {
        data: fetchUserNotificationsData.data,
        error: "",
        status: action.status,
      };
    case "FETCH_USER_NOTIFICATIONS_ERROR":
      return {
        data: [],
        error: action.payload,
        status: action.status,
      };
    default:
      return state;
  }
}
  
const practices: any = {
  data: [],
  error: "",
  status:"idle",
};

export const getAllPracticesReducer = (state = practices, action: any) => {
  switch (action.type) {
    case "GET_ALL_PRACTICES":
      return {
        data: [...action.payload],
        error: "",
      };
    case "GET_ALL_PRACTICES_ERROR":
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const fetchUserNotificationsData: any = {
  data: [],
  error: "",
  status: "idle"
};
