import { toastService } from "@tg/react-ui-kit";
import { AnyAction, Dispatch } from "redux";
import store from "../redux/store";

function AppendAuthHeader(request: Request) {
  const token = localStorage.getItem("token");
  if (token) {
    request.headers.append("Authorization", `Bearer ${token}`);
  }
  if (["POST", "PUT", "PATCH"].includes(request.method)) {
    request.headers.delete("Content-Type");
    request.headers.append("Content-Type", "application/json");
  }
  return request;
}
function AppendFormAuthHeader(request: Request) {
  const token = localStorage.getItem("token");
  if (token) {
    request.headers.append("Authorization", `Bearer ${token}`);
  }
  
  return request;
}

export async function handleResponse(
  response: Response,
  request: Request,
  body: any,
  actionType?: string
) {
  if (response.ok) {
    store.dispatch({
      type: "RESET_LOGOUT_STATE",
    });
    return response.json();
  } else if (response.status === 401) {
    store.dispatch({
      type: "LOGOUT_USER",
    });
    throw response;
  } else {
    store.dispatch({
      type: "RESET_LOGOUT_STATE",
    });
      throw response;
  }
}

export async function handleBlobResponse(
  response: Response,
  request: Request,
  body: any,
  actionType?: string
) {
  if (response.ok) {
    store.dispatch({
      type: "RESET_LOGOUT_STATE",
    });
    return response.blob();
  } else if (response.status === 401) {
    store.dispatch({
      type: "LOGOUT_USER",
    });
    throw response;
  } else {
    store.dispatch({
      type: "RESET_LOGOUT_STATE",
    });
      throw response;
  }
}

export function fetchData(request: Request, actionType: string, body?: any) {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: "RESET_LOGOUT_STATE",
    });
    dispatch({
      type: actionType + "_LOADING",
      payload: [],
      status: "loading",
    });
    fetch(AppendAuthHeader(request)).then((response) => {
      handleResponse(response, request, body, actionType)
        .then((responseJson: any) => {
          if (responseJson.message === "Success") {
            return dispatch({
              type: actionType,
              payload: responseJson.data,
              status: "succeeded",
            });
          } else {
            return dispatch({
              type: actionType + "_ERROR",
              payload: responseJson.message,
              status: "failed",
            });
          }
        })
        .catch(async (error: any) => {
          const errResp = await error.json();
          return dispatch({
            type: actionType + "_ERROR",
            payload: errResp,
            status: "failed",
          });
        });
    });
  };
}

export function returnPromise(request: Request, body?: any) {
  store.dispatch({
    type: "RESET_LOGOUT_STATE",
  });
  return fetch(AppendAuthHeader(request)).then((res) => {
    return handleResponse(res, request, body);
  });
}

export function returnBlobPromise(request: Request, body?: any) {
  store.dispatch({
    type: "RESET_LOGOUT_STATE",
  });
  return fetch(AppendAuthHeader(request)).then((res) => {
    return handleBlobResponse(res, request, body);
  });
}

export function returnFormDataPromise(request: Request, body?: any){
  store.dispatch({
    type: "RESET_LOGOUT_STATE",
  });
  return fetch(AppendFormAuthHeader(request)).then((res) => {
    return handleResponse(res, request, body);
  });
}

export function resolveFilePromise(request: Request, fileName : string,body?: any){
  store.dispatch({
    type: "RESET_LOGOUT_STATE",
  });
  return fetch(AppendAuthHeader(request)).then((res) => {
    if(res.ok){
      return res.blob().then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName+'.xlsx'); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
    }
    throw res.statusText;
  })
  .catch((error) => {
    toastService.addToast({
        text: "Failed to export",
        options: { timeout: 5 },
        level: "error",
    });
    return Promise.reject({ Error: 'Something Went Wrong', error });
  })
}


export function resolveAllFilePromise(request: Request, fileName : string,body?: any,type?:any){
  store.dispatch({
    type: "RESET_LOGOUT_STATE",
  });
  return fetch(AppendAuthHeader(request)).then((res) => {
    if(res.ok){
      return res.blob().then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName+'.'+type); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toastService.addToast({
          text: "Document downloaded successfully",
          options: { timeout: 5 },
          level: "success",
      });
      })
    }
    throw res.statusText;
  })
  .catch((error) => {
    toastService.addToast({
        text: "Failed to Download",
        options: { timeout: 5 },
        level: "error",
    });
    return Promise.reject({ Error: 'Something Went Wrong', error });
  })
}