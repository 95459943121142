import { API_SLUG_CONSTANTS } from "../../constants/apiConstants";
import { returnPromise , fetchData, resolveFilePromise, returnFormDataPromise, returnBlobPromise, resolveAllFilePromise } from "../../helpers/fetchData";
import { DownloadFileRequest, ProjectDocumentRequest} from "../../models/api/requests/ProjectDocumentRequest";
const FETCH_EMPLOYEE_PROJECTS = "FETCH_EMPLOYEE_PROJECTS";
const API_URL = process.env.REACT_APP_API_URL || "http://3.111.95.51:8080/api";

export const fetchEmployeeByIdAction = (id: any) => {
  var requestAccount = new Request(
    API_URL + `${API_SLUG_CONSTANTS.FETCH_EMPLOYEE_BY_ID}${id}`,
    {
      method: "GET",
    }
  );
  return returnPromise(requestAccount);
};

export const fetchProjectsByEmployeeId =  (employeeRequest: any) => {
  var requestProject = new Request(
    API_URL + `${API_SLUG_CONSTANTS.FETCH_EMPLOYEE_PROJECTS}`,
    {
      method: "POST",
      body: JSON.stringify(employeeRequest),
    }
  );
  return fetchData(requestProject, FETCH_EMPLOYEE_PROJECTS ,JSON.stringify(employeeRequest) );
};

export const fetchBillableEmployeeDetails =  (getEmployeeProjectMapRequest: any) => {
  var requestProject = new Request(
    API_URL + `${API_SLUG_CONSTANTS.GET_BILLABLE_EMPLOYEE_DETAILS}`,
    {
      method: "POST",
      body: JSON.stringify(getEmployeeProjectMapRequest),
    }
  );
  return returnPromise(requestProject)
};
export const fetchEmployeesAction = (body: any = null) => {
  var requestEmployee = new Request(
    API_URL + API_SLUG_CONSTANTS.FETCH_EMPLOYEES,
    {
      method: "POST",
      body: body == null ? JSON.stringify({}) : JSON.stringify(body),
    }
  );
  return returnPromise(requestEmployee , body == null ? JSON.stringify({}) : JSON.stringify(body));
};
export const exportEmployeeExcelData = (employeeIds : any[]) => {
  var requestExcel = new Request(
    API_URL +API_SLUG_CONSTANTS.GET_EMPLOYEE_EXCEL,
    {
      method: "POST",
      body: JSON.stringify(employeeIds),
    }
  );
  resolveFilePromise(requestExcel , "Employee" , JSON.stringify(employeeIds));
}

export const fetchResourceTimeLinesAction = (body: any = null) => {
  var requestResourceTimelines = new Request(
    API_URL + API_SLUG_CONSTANTS.GET_RESOURCE_TIMELINES,
    {
      method: "POST",
      body: body == null ? JSON.stringify({}) : JSON.stringify(body),
    }
  );
  return returnPromise(requestResourceTimelines);
};


export const fetchResourceRequestTimeLinesAction = (body: any = null) => {
  var requestResourceTimelines = new Request(
    API_URL + API_SLUG_CONSTANTS.GET_RESOURCE_REQUEST_TIMELINES,
    {
      method: "POST",
      body: body == null ? JSON.stringify({}) : JSON.stringify(body),
    }
  );
  return returnPromise(requestResourceTimelines);
};

export const createEmployeeDocument=(formatData:FormData) => {
  var requestEmployeeDocuments = new Request(
    API_URL + API_SLUG_CONSTANTS.CREATE_EMPLOYEE_DOCUMENT,
    {
      method: "POST",
      body: formatData == null ? JSON.stringify({}) : formatData, 
      headers: {'Accept':`application/json`}
    });
  return returnFormDataPromise(requestEmployeeDocuments);
}

export const getEmployeeDocuments=(employeeId:number)=>{
  var request = new Request(
    API_URL + `${API_SLUG_CONSTANTS.GET_EMPLOYEE_DOCUMENT}${employeeId}`,
    {
      method: "GET",
    }
  );
  return returnPromise(request);
}

export const getSkillsByEmployeeId = (employeeId: number) => {
  var requestSkillTracking = new Request(
    API_URL + `${API_SLUG_CONSTANTS.GET_EMPLOYEE_SKILLS}${employeeId}`,
    {
      method: "GET",
    }
  );
  return returnPromise(requestSkillTracking);
}

export const getCertificationsByEmployeeId = (employeeId: number) => {
  var requestCertificationTracking = new Request(
    API_URL + `${API_SLUG_CONSTANTS.GET_EMPLOYEE_CERTIFICATIONS}${employeeId}`,
    {
      method: "GET",
    }
  );
  return returnPromise(requestCertificationTracking);
}

export const downloadEmployeeDocument=(body:any=null,fileName:string,type:string)=>{
  var requestResourceTimelines = new Request(
    API_URL + `${API_SLUG_CONSTANTS.DOWNLOAD_EMPLOYEE_DOCUMENT}`,
    {
      method: "POST",
      body: body == null ? JSON.stringify({}) : JSON.stringify(body), 
    }
  );
  return resolveAllFilePromise(requestResourceTimelines,fileName,"",type);
}

export const deleteEmployeeDocument=(body:any)=>{
  var requestEmployeeDocuments = new Request(
    API_URL + API_SLUG_CONSTANTS.DELETE_EMPLOYEE_DOCUMENT,
    {
      method: "POST",
      body: body == null ? JSON.stringify({}) : JSON.stringify(body), 
    });
  return returnPromise(requestEmployeeDocuments);
}

export const getProjectDocuments=(body: ProjectDocumentRequest)=>{
  var requestEmployeeDocuments = new Request(
    API_URL + API_SLUG_CONSTANTS.GET_PROJECT_DOCUMENTS,
    {
      method: "POST",
      body: JSON.stringify(body), 
    });
  return returnPromise(requestEmployeeDocuments);
}

export const getDocumentPreviewURL = (body: DownloadFileRequest) => {
  let documentPreviewRequest = new Request(API_URL + API_SLUG_CONSTANTS.GET_DOCUMENT_PREVIEW, {
    method: 'POST',
    body: JSON.stringify(body)
  });
  return returnPromise(documentPreviewRequest);
}

export const deleteProjectDocument=(body:any)=>{
  var requestEmployeeDocuments = new Request(
    API_URL + API_SLUG_CONSTANTS.DELETE_PROJECT_DOCUMENTS,
    {
      method: "POST",
      body: body == null ? JSON.stringify({}) : JSON.stringify(body), 
    });
  return returnPromise(requestEmployeeDocuments);
}

export const downloadProjectDocument=(body:any=null,fileName:string,type:string)=>{
  var requestResourceTimelines = new Request(
    API_URL + `${API_SLUG_CONSTANTS.DOWNLOAD_PROJECT_DOCUMENTS}`,
    {
      method: "POST",
      body: body == null ? JSON.stringify({}) : JSON.stringify(body), 
    }
  );
  return resolveAllFilePromise(requestResourceTimelines,fileName,"",type);
}

export const getDocumentblob = (body: any = null, fileName: string, type: string) => {
  const request = new Request(
    `${API_URL}${API_SLUG_CONSTANTS.DOWNLOAD_PROJECT_DOCUMENTS}`,
    {
      method: "POST",
      body: body == null ? JSON.stringify({}) : JSON.stringify(body), 
    }
  );

  return returnBlobPromise(request);
}

export const addEmployeeActivity=(body:any)=>{
  var employeeActivityRequest=new Request(
    API_URL + API_SLUG_CONSTANTS.ADD_EMPLOYEE_ACTIVITY,
    {
      method: "POST",
      body: body == null ? JSON.stringify({}) : JSON.stringify(body)
    });
    return returnPromise(employeeActivityRequest);
}
export const updateEmployeeActivity=(body:any)=>{
  var employeeActivityRequest=new Request(
    API_URL + API_SLUG_CONSTANTS.UPDATE_EMPLOYEE_ACTIVITY,
    {
      method: "POST",
      body: body == null ? JSON.stringify({}) : JSON.stringify(body)
    });
    return returnPromise(employeeActivityRequest);
}

export const getEmployeeActivities=(body:any)=>{
  var employeeActivitiesRequest=new Request(
    API_URL + API_SLUG_CONSTANTS.GET_EMPLOYEE_ACTIVITY,
    {
      method: "POST",
      body: body == null ? JSON.stringify({}) : JSON.stringify(body)
    });
    return returnPromise(employeeActivitiesRequest);
}

export const getActivityYears=()=>{
  var activityYearsRequest=new Request(
    API_URL + API_SLUG_CONSTANTS.GET_EMPLOYEE_ACTIVITYYEARS,{
      method: "GET",
    }
  )
  return returnPromise(activityYearsRequest);
}
export const getActivityMonths=()=>{
  var activityMonthsRequest=new Request(
    API_URL + API_SLUG_CONSTANTS.GET_EMPLOYEE_ACTIVITYMONTHS,{
      method: "GET",
    }
  )
  return returnPromise(activityMonthsRequest);
}

export const createFutureProjections = (request: any)  => {
  var requestFutureProjections = new Request(
      API_URL + API_SLUG_CONSTANTS.CREATE_FUTURE_PROJECTIONS,
      {
      method: "POST",
      body: JSON.stringify(request)
      }
  );
  return returnPromise(requestFutureProjections , JSON.stringify(request));
};

export const editFutureProjections=(body:any)=>{
  var requestFutureProjections = new Request(
    API_URL + API_SLUG_CONSTANTS.EDIT_FUTURE_DETAILS,
    {
      method: "PUT",
      body: body == null ? JSON.stringify({}) : JSON.stringify(body), 
    });
  return returnPromise(requestFutureProjections);
}

export const deleteFutureProjection=(futureProjectionId:any)=>{
  var requestFutureProjectionDocuments = new Request(
    API_URL + `${API_SLUG_CONSTANTS.DELETE_FUTURE_PROJECTIONS}?futureProjectionId=${futureProjectionId}`,
    {
      method: "DELETE",
    });
  return returnPromise(requestFutureProjectionDocuments);
}

export const getFutureProjections=(empId: any)=>{
  var requestFutureProjections = new Request(
    API_URL + `${API_SLUG_CONSTANTS.GET_FUTURE_PROJECTIONS}?EmployeeId=${empId}`,
    {
      method: "GET", 
    });
    return returnPromise(requestFutureProjections);
}