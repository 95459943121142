import Project from "../../models/api/responses/Project";
import { FETCH_PROJECT_BY_PROJECT_ID } from "../../constants/reduxConstants";
import ProjectContact from "../../models/api/requests/ProjectContact";
import FetchProject from "../../models/api/responses/FetchProject";

const projectInitialValues: any = {
  data: new Project(),
  error: "",
  status:"idle",
};

const contactRequestInitialValues: any = {
  data: new ProjectContact(),
  error: "",
  status:"idle",
};

const fetchProjectsData: any = {
  data: new FetchProject(),
  error: "",
  status:"idle",
};
const initialValues: any = {
  data: [],
  error: "",
  status:"idle",
};

export const fetchProjectsReducer = (
  state = fetchProjectsData,
  action: any
) => {
  switch (action.type) {
    case "FETCH_PROJECTS":
      return {
        ...state,
        data: { ...action.payload },
        error: "",
        status:action.status,
      };
    case "FETCH_PROJECTS_LOADING":
      return {
        data: fetchProjectsData.data,
        error: "",
        status:action.status,
      };
    case "FETCH_PROJECTS_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const projectStatusReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_PROJECT_STATUS":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_PROJECT_STATUS_LOADING":
      return {
        data: [],
        error: "",
        status:action.status
      };
    case "FETCH_PROJECT_STATUS_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const projectTypeReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_PROJECT_TYPE":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_PROJECT_TYPE_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_PROJECT_TYPE_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const futureProjectionStatusReducer = (state = initialValues, action: any) => {  
  switch (action.type) {
    case "FETCH_FUTURE_STATUS":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_FUTURE_STATUS_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_FUTURE_STATUS_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};
export const projectNamesReducer = (state = initialValues, action: any) => {  
  switch (action.type) {
    case "FETCH_PROJECT_NAMES":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_PROJECT_NAMES_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_PROJECT_NAMES_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const skillsNamesReducer = (state = initialValues, action: any) => {  
  switch (action.type) {
    case "FETCH_SKILL_NAMES":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_SKILL_NAMES_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_SKILL_NAMES_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const futureProjectionStatusDetailsReducer = (state = initialValues, action: any) => {  
  switch (action.type) {
    
    case "FETCH_FUTURE_STATUS_DETAILS":
        return {
          data: [...action.payload],
          error: "",
          status:action.status,
        };
    case "FETCH_FUTURE_STATUS_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_FUTURE_STATUS_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};
export const futureProjectionExtraDetailsReducer = (state = initialValues, action: any) => {  
  switch (action.type) {
    case "FETCH_FUTURE_EXTRA_DETAILS":
          return {
            data: [...action.payload],
            error: "",
            status:action.status,
          };
    case "FETCH_FUTURE_STATUS_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_FUTURE_STATUS_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};
export const projectTimeZoneReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_PROJECT_TIMEZONE":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_PROJECT_TIMEZONE_LOADING":
      return {
        data: [],
        error: "",
        status:action.status
      };
    case "FETCH_PROJECT_TIMEZONE_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const projectCategoryReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_PROJECT_CATEGORY":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_PROJECT_CATEGORY_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_PROJECT_CATEGORY_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};


export const projectPlatformReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_PROJECT_PLATFORM":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_PROJECT_PLATFORM_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_PROJECT_PLATFORM_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const fetchAllEmployeesReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_ALL_EMPLOYEES":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_ALL_EMPLOYEES_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_ALL_EMPLOYEES_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};


export const ProjectByIdReducer = (
  state = projectInitialValues,
  action: any
) => {
  switch (action.type) {
    case FETCH_PROJECT_BY_PROJECT_ID:
      return {
        data: action.payload,
        error: "",
        status:action.status,
      };
    case FETCH_PROJECT_BY_PROJECT_ID + "_LOADING":
      return {
        data: projectInitialValues.data,
        error: "",
        status:action.status,
      };
    case FETCH_PROJECT_BY_PROJECT_ID + "_ERROR":
      return {
        data: projectInitialValues.data,
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const createProjectContactReducer = (
  state = contactRequestInitialValues,
  action: any
) => {
  switch (action.type) {
    case "CREATE_PROJECT_CONTACT":
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        error: "",
        status:action.status,
      };
    case "CREATE_PROJECT_CONTACT_LOADING":
      return {
        data: contactRequestInitialValues.data,
        error: "",
        status:action.status,
      };
    case "CREATE_PROJECT_CONTACT_ERROR":
      return {
        data: contactRequestInitialValues.data,
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const projectFilterBodyReducer = (
  state = [],
  action : any
) => {
  switch(action.type) {
    case "UPDATE_PROJECT_FILTER_BODY":
      return [...action.payload];
    case "RESET_PROJECT_FILTER_BODY":
      return [];
    default:
      return state;
  }
}


