import React, { useState, useEffect, Dispatch } from "react";
import SVG from "react-inlinesvg";
import { TgInitialAvatar } from "@tg/react-ui-kit";
import "./NotificationDropDown.scss";
import {
  getNotifications,
  getUserNotificationss,
  updateUserNotificationStatus,
} from "../../../redux/Action/ApplicationAction";
import { notificationRanges, getCustomTime, getDisplayDate, NotificationResponse, ToolbarNotification, getDescription } from './NotificationUtilities'
import { useAppDispatch } from "../../../shared/hooks/customHooks";
import { useSelector } from "react-redux";

function NotificationDropdown(props: any) {
  const [notificationDropData, setNotificationData] = useState<ToolbarNotification[]>([]);
  const [rangeDropdownValue, setRangeDropdownValue] = useState("1");
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const dispatch = useAppDispatch();

  const notificationResponse = useSelector(
    (state: any) => state.fetchUserNotification.data
  );

  useEffect(()=>{
    var notificationsResponse: NotificationResponse[] = notificationResponse;
    if (notificationsResponse && notificationsResponse.length > 0) {
      notificationsResponse.sort((item1, item2) => {
        if (item1.createdDate > item2.createdDate) return -1;
        if (item1.createdDate < item2.createdDate) return 1;
        return 0;
      });
      let toolbarNotifications: ToolbarNotification[] = []
      toolbarNotifications = notificationsResponse.map((element) => {
        return {
           ...element,
          description: getDescription(element && element.formatDescription, element && element),
          createdBy:element && element.createdByName,
          createdDate: getDisplayDate(element && element.createdDate),
        };
      });
      setNotificationData(toolbarNotifications);
    }
  },[notificationResponse]);

  useEffect(() => {
    getUserNotifications();
  }, [props.refresh]);


  useEffect(() => {
    let unreadCount = (notificationDropData ?? [])?.filter(x => !x.isRead).length;
    setUnreadNotificationCount(unreadCount);
    props.setUnreadNotifCount(unreadCount);
  }, [
    notificationDropData
  ])

  const getUserNotifications = async (startDate?: Date | null) => {
    const request = startDate == null ? null : { startDate };
    dispatch(getUserNotificationss(request));
  };

  const handleRangeChange = (e: any) => {
    setRangeDropdownValue(e.target.value);
    getUserNotifications(notificationRanges[e.target.value - 1].value);
  };

  const handleMarkAllRead = async (notificationDropData: any) => {
    let notificationIds: number[] = [];
    notificationDropData.map((notification: any) => {
      !notification.IsRead && notificationIds.push(notification.notificationId);
    });
    await updateUserNotificationStatus({
      notificationIds: notificationIds,
      isRead: true,
    });
    getUserNotifications();
  };

  const handleToggle = async (
    notificationDropData: any,
    index: number,
    isRead: boolean
  ) => {
    var id = notificationDropData[index].notificationId;
    await updateUserNotificationStatus({
      notificationIds: [id],
      isRead: !isRead,
    });
    if (notificationDropData[index].isRead) {
      setUnreadNotificationCount(unreadNotificationCount + 1);
      props.setUnreadNotifCount(unreadNotificationCount + 1);
    } else {
      setUnreadNotificationCount(unreadNotificationCount - 1);
      props.setUnreadNotifCount(unreadNotificationCount - 1);
    }
    notificationDropData[index].isRead = !notificationDropData[index].isRead;
    setNotificationData([...notificationDropData]);
  };



  let notificationListItems = notificationDropData.map(
    (element: any, i: any) => {
      return (
        <>
          <div
            className={
              "buzz-notification-dropdown-list " +
              (element.isRead ? "notification-read" : "notification-not-read")
            }
          >
            <div className="notification-dot">
              {element.isRead ? (
                <></>
              ) : (
                <>
                  <svg
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="4"
                      cy="4"
                      r="3.5"
                      fill="#064A88"
                      stroke="#064A88"
                    />
                  </svg>
                </>
              )}
            </div>

            <div className="notification-body">
              <div className="d-flex notification-description">
                <TgInitialAvatar
                  name={String(element.createdBy)}
                ></TgInitialAvatar>
                <div
                  dangerouslySetInnerHTML={{ __html: element.description }}
                />
              </div>
            </div>
            <div className="notification-date">
              {element.createdDate}
              <div
                className="notification-action"
                onClick={(event) => {
                  event.stopPropagation();
                  handleToggle(notificationDropData, i, element.isRead);
                }}
              >
                {element.isRead ? <>Mark as Unread</> : <>Mark as Read</>}
              </div>
            </div>
          </div>
        </>
      );
    }
  );

  if (notificationDropData.length == 0) {
    notificationListItems.push(
      <div className="empty-notifications">
        There are no notifications to display
      </div>
    );
  }
  return (
    <>
      <div className="buzz-notification-list"
        onClick={(e: any) => { e.stopPropagation() }}>
        <div className="notification-header">
          <div
            className="notification-range"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            Notifications
            <div>
              <select
                className="notification-range-select-container"
                value={rangeDropdownValue}
                onChange={handleRangeChange}
              >
                {notificationRanges.map((option) => (
                  <option value={option.key}>{option.label}</option>
                ))}
              </select>
            </div>
          </div>
          <div
            className="notification-mark-all-as-read"
            onClick={(event) => {
              event.stopPropagation();
              handleMarkAllRead(notificationDropData);
            }}
          >
            Mark all as Read
            <SVG
              src={process.env.PUBLIC_URL + "/assets/icons/markallasread.svg"}
            ></SVG>
          </div>
        </div>
        {notificationListItems}
      </div>
    </>
  );
}

export default NotificationDropdown;
