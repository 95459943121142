import React, { useEffect, useState } from "react";
import {  useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import RoutesConfig from "./config/routes/routesConfig";
import { useNavigate } from "react-router";
import { loginRequest } from "./authConfig";
import { authorizeToken } from "./redux/Action/ApplicationAction";
import jwt from 'jwt-decode';
import { loaderService } from "@tg/react-ui-kit";
import { AuthProvider } from './config/authorization/AuthContext';
import Spinner from 'react-bootstrap/Spinner';
import {createWebSocket} from './services/WebSocketService';

export const UserDetails = React.createContext({});
function App() {
  const { instance, accounts } = useMsal();
  useEffect(() => {
    OnLoad();
    const callbackId = instance.addEventCallback((message: any) => {
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        instance.setActiveAccount(accounts[0]);
      }
    });
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);
  const [userData, setUserData] = useState<any>({});
  const [isTokenSet, setIsTokenSet] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  async function OnLoad() {
    // loaderService.addSync();
    
    userData.name = accounts[0]?.name || "";
    if (!localStorage.getItem("token")) {
      await instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts && accounts[0],
        })
        .then((response) => {
          authorizeToken(response.accessToken)
            .then((response: any) => {
              userData.token = response?.data?.token || "";
              userData.refreshToken = response?.data?.refreshToken || "";
              localStorage.setItem("token", response?.data?.token);
              localStorage.setItem(
                "refreshtoken",
                response?.data?.refreshToken
              );
              var obj : any = jwt(response?.data?.token);
              setIsTokenSet(true)
              // if(obj.RoleName !== process.env.REACT_APP_ADMIN){
              //   throw new Error("not authorized");
              // }
            })
            .catch((error) => {
              console.log('error = ', error)
              navigate("/unauthorized");
              const logoutRequest = {
                account: instance.getAccountByHomeId("homeAccountId"),
                onRedirectNavigate: (url: string) => {
                  return false;
                },
              };
              instance.logoutRedirect(logoutRequest);
              localStorage.clear();
            })
            .finally(() => {
              loaderService.hideSync();
              setIsLoading(false);
            })
        })
        .catch((error) => {
          console.log(error);
          navigate("/login");
        });
    } else {
      setIsTokenSet(true)
      setIsLoading(false);
    }
    createWebSocket();
  }
  if (isLoading) {
    return (
      <div className="App" id="app">
        <div className="loading-container">
          <div className="loading-message">Getting Data ...<Spinner className="getting-data-spinner" animation="grow" variant="primary" /></div>
        </div>
      </div>
    );
  }
  else {
    return (
      <div className="App" id="app">
        <>
          <UserDetails.Provider value={userData}>
            {isTokenSet && (
              <AuthProvider>
                <RoutesConfig />
              </AuthProvider>
            )}
          </UserDetails.Provider>
        </>
      </div>
    );
  }
}

export default App;
