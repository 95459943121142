import store from '../redux/store';

import { NotificationResponse } from '../views/components/Notifications/NotificationUtilities';

const createWebSocket = () => {
  
  console.log('Received CreateWebSocket Request');
  
  const maxRetryCount = 3;
  const webSocketURL = process.env.REACT_APP_WEBSOCKET_URL;

  let currentRetryCount = 1, interval: number, webSocket: WebSocket;

  const retry = () => {
    window.setTimeout(() => {
      if (currentRetryCount <= maxRetryCount) {
        create();
        console.log(`Retrying Websocket Connection. (${currentRetryCount}/${maxRetryCount}) attempts left`);
        currentRetryCount++;
      } else {
        console.log('Connection failed after maximum retries.');
      }
    }, 2000);
  }

  const create = () => {
    const token = encodeURIComponent(localStorage.getItem('token') || '');
    webSocket = new WebSocket(`${webSocketURL}?token=${token}`);

    webSocket.onopen = () => {
      console.log('Websocket Connection Established');
      currentRetryCount = 1;
      
      // Ping for every 2 mins to prevent connection loss  
      interval = window.setInterval(() => {
        webSocket.send('ping');
      }, 120000);
    }

    webSocket.onmessage = (event) => {
      console.log('Received Message: ', event);
      //TODO Implement the filter logic based on event type
      dispatchNotifications(JSON.parse(event.data) as NotificationResponse[]);
    }
  
    webSocket.onclose = (event) => {
      console.log('Websocket Connection Closed.');
      clearInterval(interval);
      destroyWebsocket();
      retry();
    }
  }

  create();

  const destroyWebsocket = () => {
    webSocket.onopen = null;
    webSocket.onmessage = null;
    webSocket.onclose = null;
    webSocket.close();
  }

  window.addEventListener('online', () => {
    console.log('Reconnected to Internet');
    currentRetryCount = 1;
    retry();
  });

  window.addEventListener('offline', () => {
    console.log('Disconnected from Internet');
    clearInterval(interval);
    destroyWebsocket();
  });
}

const dispatchNotifications = (data: NotificationResponse[]) => {
  store.dispatch({
    type: 'FETCH_USER_NOTIFICATIONS',
    payload: data,
    status: 'succeeded',
  });
}

export { createWebSocket }
