export const formatDate = (selectedDate: any, formatter: string, seperator: string) => {
    if(!selectedDate)
        return;
    
    if(formatter === 'yyyy-mm-dd' || formatter === 'yyyy/mm/dd') {
        return new Date(selectedDate)
            .toLocaleDateString('en-GB', {year: 'numeric', month: '2-digit', day: '2-digit'})
            .split('/')
            .reverse()
            .join(seperator);
    } else if(formatter === 'dd-mm-yyyy' || formatter === 'dd/mm/yyyy') {
        return new Date(selectedDate)
            .toLocaleDateString('en-GB', {year: 'numeric', month: '2-digit', day: '2-digit'})
            .split('/')
            .join(seperator);
    }
}
