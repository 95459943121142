import {
  deleteEmployeeDocument,
  downloadEmployeeDocument,
  fetchEmployeeByIdAction,
  getDocumentPreviewURL,
  getEmployeeDocuments,
} from "../../../../redux/Action/EmployeeAction";
import React, { useContext, useEffect, useRef, useState } from "react";

import { modalService, toastService } from "@tg/react-ui-kit";
import { formatDate } from "../../../../helpers/formatdate";
import Grid from "../../../../shared/components/Grid/Grid";
import EmployeeDocumentInfo from "../../../../models/api/responses/EmployeeDocumentInfo";
import SVG from "react-inlinesvg";
import { useParams } from "react-router";
import "./EmployeeDocument.scss";
import { FileDragandDrop } from "./FileDragandDrop"
import { useAppDispatch } from "../../../../shared/hooks/customHooks";
import { AuthContext } from './../../../../config/authorization/AuthContext';
import { DOCUMENT_ADD, DOCUMENT_DOWNLOAD, DOCUMENT_EDIT, DOCUMENT_READ } from './../../../../config/authorization/PermissionsConstants';
import { confirmModal } from "../../../../helpers/confirmModal";
import { useMemo } from "react";
import { BehaviorSubject } from "rxjs";
import { setPageDetailsAction } from "../../../../redux/Action/ApplicationAction";
import { useSelector } from "react-redux";
import DocViewer, { IDocument, DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { DownloadFileRequest } from "@src/models/api/requests/ProjectDocumentRequest";
import { VideoTypesInDocuments } from "src/enums/DocumentTypes";

export function EmployeeDocuments() {

  const dispatch = useAppDispatch();
  const authContext = useContext(AuthContext);
  const $dirty=useMemo(()=> new BehaviorSubject<boolean>(false),[])
  const canReadDocument = authContext && authContext.checkPermission([DOCUMENT_READ]);
  const canEditDocument = authContext && authContext.checkPermission([DOCUMENT_EDIT]);
  const canAddDocument = authContext && authContext.checkPermission([DOCUMENT_ADD]);
  const canDownloadDocument = authContext && authContext.checkPermission([DOCUMENT_DOWNLOAD]);


  const [totalRecords, setTotalRecords] = useState<EmployeeDocumentInfo[]>([]);
  const [rowData, setRowData] = useState<EmployeeDocumentInfo[]>([]);
  const [loadEmployeeDocuments, LoadEmployeeDocuments] = useState<boolean>(true);

  const { id } = useParams();
  const employeeId = Number(id);

  const employeeName = useSelector(
    (state:any)=> state.employeeById.data.employeeName
  )

  const getDocumentPreview = (url: string) => {
    return getDocumentPreviewURL({ url: url } as DownloadFileRequest);
  }

  useEffect(()=>{
    employeeId &&
    fetchEmployeeByIdAction(employeeId)
      .then((response: any) => {
        dispatch({
          type: "FETCH_EMPLOYEE_BY_EMPLOYEE_ID",
          payload: response.data,
        });
      })
      .catch((error: any) => {
        toastService.addToast({
          text: error.message || "Failed to fetch employee data",
          options: { timeout: 5 },
          level: "error",
        });
      });
  }, [])
  
  useEffect(() => {
    dispatch(
      setPageDetailsAction({
        pageTitle: employeeName,
        breadCrumb: [
          {
            title: "Employees",
            url:"/employee",
          },
          {
            title: employeeName,
            url: `/employee/${id}/employee360/overview`,
          },
          { title: "documents" },
        ],
      })
    );
  }, [employeeName]);

  const downloadDocument = (url: string, fileName: string) => {
    const fName = fileName.split('.')[0];
    const type = fileName.split('.')[1];
    downloadEmployeeDocument({ url: url }, fName, type ?? "")
      .then((response: any) => {
      }).catch((error) => {
        console.log(error);
      })
  }

  const deleteDocument = (props: any) => {
    const employeeDocumentId = props.data.employeeDocumentId;
    const documentPath = props.data.documentPath;
    deleteEmployeeDocument({ employeeDocumentId: employeeDocumentId, documentPath: documentPath, employeeId: employeeId }).then((response: any) => {
      loadEmployeeDocument();
    }).catch((error: any) => {
      console.log(error);
    })
  }

  const documentActions = (props: any) => {
    return (
      <div className="actions-menu">
        
        {canDownloadDocument && 
        <span onClick={() => downloadDocument(props.data.documentPath, props.data.documentName)}>
        <SVG src={process.env.PUBLIC_URL + "/assets/icons/download.svg"}></SVG>
        </span>
        }
        {canEditDocument && 
        <span onClick={() => {
          confirmModal(
            () => {
              deleteDocument(props)
              toastService.addToast({
                text: "Document Deleted successfully",
                options: { timeout: 5 },
                level: "success",
              });
            },
            () => { },
            "Are you sure you want to delete the document?",
            "Delete Document"
          )
        }
        }>
        <SVG src={process.env.PUBLIC_URL + "/assets/icons/delete.svg"}></SVG>
        </span>
        }
        {
          <span onClick={() => {
            getDocumentPreview(props.data.documentPath)
            .then(res => {
              const fileNameSplit = props.data.documentName.split('.') as string[];
              const doc: IDocument = {
                uri: res?.data ?? '',
                fileType: fileNameSplit[fileNameSplit.length - 1]
              }

              let docElement;
              if(doc.fileType){
                if(VideoTypesInDocuments.ListOfVideoTypes.includes(doc.fileType.toLowerCase())){
                  docElement = <video src={doc.uri} width="1115px" height="400px" controls />;
                }
                else{
                  docElement = <DocViewer pluginRenderers={DocViewerRenderers} documents={[doc]} style={{height: '400px', width: '100%'}} />;
                }
              }
              document.body.classList.add('no-scroll-body');
              modalService.open({
                element: docElement,
                title: props.data.displayName,
                maxWidth: '1200px',
                maxHeight: '700px',
                onClose: ()=>{
                  document.body.classList.remove('no-scroll-body');
                  modalService.close()
                }
              });   
            });
          }}>
            <SVG src={process.env.PUBLIC_URL + "/assets/icons/eye.svg"}></SVG>
          </span>
        }
      </div>
    );
  }

  const columnDefs = [
    {
      field: "displayName",
      headerName: "DOCUMENT NAME",
      cellRenderer: (props: any) => {
        return <span className="grid-column-name">{props?.value}</span>;
      },
    },
    {
      field: "documentSize",
      headerName: "DOCUMENT SIZE (MB)",
      cellRenderer: (props: any) => {
        return <span>{props?.value}</span>;
      }
    },
    {
      field: "uploadedBy",
      headerName: "UPLOADED BY",
    },
    {
      field: "uploadedTime",
      headerName: "UPLOADED ON",
      cellRenderer: (props: any) => {
        return <span>{formatDate(props?.value, "dd-mm-yyyy", "-")}</span>;
      }
    },
    {
      field: "accountId",
      headerName: "ACTIONS",
      cellRenderer: documentActions,
      sortable: false,
    },
  ];

  useEffect(() => {
    getEmployeeDocuments(employeeId).then(response => {
      setTotalRecords(response.data);
    })
  }, [loadEmployeeDocuments]);

  useEffect(() => {
    setRowData(totalRecords);
  }, [totalRecords])

  const loadEmployeeDocument = () => {
    LoadEmployeeDocuments(!loadEmployeeDocuments)
  }

  return (
    <>
      {
        canAddDocument &&
        <div className="upload-document">
          <button className="grid-action-button"
            onClick={() => {
              $dirty.next(false);
              document.body.classList.add('no-scroll-body');
              modalService.open({
                title: "Upload Document",
                subTitle: "",
                element: <FileDragandDrop 
                loadEmployeeDocument={loadEmployeeDocument}
                employeeId={employeeId} 
                $dirty={$dirty}
                />,
                onClose:()=> {
                  document.body.classList.remove('no-scroll-body');
                  let isDirty = $dirty.getValue();
                  isDirty
                    ? confirmModal(
                        () => {
                          modalService.close();
                        },
                        () => {},
                        "Are you sure to close the form? the changes made won't be saved."
                      )
                    : modalService.close();
                },
              });
            }}
          >
            Upload Document
          </button>
        </div>
      }
      {
        canReadDocument ? <>
          <div className="documents">
            <Grid rowData={rowData}
              rowCount={totalRecords.length}
              columnDefs={columnDefs}
            />
          </div>
        </>
          : <p className="no-access-message">You do not have access to view Documents </p>
      }
    </>
  );
}
