import { useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import "./UnAuthorized.scss";
import SVG from "react-inlinesvg";
import TeamsAuthService from "../../../services/TeamsAuthService";

export default function UnAuthorized() {
  const navigate = useNavigate();
  const isAuthenticated = useMsal();
  useEffect(() => {
    if (isAuthenticated && localStorage.getItem("token") !== null) {
      navigate("/accounts");
    }
  }, []);
  return (
    <>
      <div className="unauth-block">
        <div className="align-items-center bg-white d-flex justify-content-center text-center unauth-container">
          <div className="unauth-inner-container d-flex justify-content-center align-items-center flex-column">
            <div className="unauth-logo">
              <SVG
                src={process.env.PUBLIC_URL + "/assets/icons/loginLogo.svg"}
              ></SVG>
            </div>
            <div className="img">
              <SVG
                src={process.env.PUBLIC_URL + "/assets/icons/unAuthorized.svg"}
              ></SVG>
            </div>
            <div className="unauth-title mt-5">Unauthorized Entry</div>
            <div className="unauth-sub-title">
              Sorry, you are not authorized to access the application.Please
              reach out to your admin.
            </div>
            {!TeamsAuthService.authState.isTeamsApp ? (
              <div
                className="unauth-button px-5 py-3 mt-4"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Back to Login
              </div>
            ) : (
              <div
                className="unauth-button px-5 py-3 mt-4"
                onClick={() => {
                  navigate("/accounts");
                }}
              >
                Try Again
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
