export class EmailId {
  emailID?: string;
}

export class MobileNumber {
  mobileNumber?: string;
}

export class updateRequest {
  contactId?: number;
  fullname?: string;
  accountId?: number;
  statusId?: number;
  contactTypeId?: number;
  usageName?: string;
  gender?: string;
  department?: string;
  associationTypeId?: number;
  jobTitle?: string;
  linkedInId?: string;
  primaryMobileNumber: MobileNumber= new MobileNumber();
  otherMobileNumbers?: MobileNumber[] = [];
  otherEmails?: EmailId[] = [];
  notes?: string;
  startDate?: string;
  endDate?: string;
}
export  class UpdateContact {
  projectId?: number;
  updateRequest: updateRequest = new updateRequest();
}
