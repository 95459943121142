import React from 'react';
import { Container } from 'react-bootstrap';

export default function TermsOfUsePage() {
  return (
    <Container className="page-container">
      <h1 className="page-title">Terms of Use</h1>
      <p>
        Welcome to OurCompany! These Terms of Use govern your use of our
        website. By accessing or using our website, you agree to be bound by
        these Terms of Use and our Privacy Policy. If you do not agree with
        these terms, please do not use our website.
      </p>
      <p>

      </p>
    </Container>
  );
};
