
import { Module, Action } from './AuthContext';
import React, { useState, useEffect, useContext, Suspense } from 'react';
import UnauthorizedPage from './UnauthorizedPage'
import { AuthContext } from './AuthContext';
import {
  loaderService
} from "@tg/react-ui-kit";

interface WithAuthorizationCheckProps {
  requiredPermissions: { module: Module; action: Action }[];
  children: React.ReactNode;
}

const WithAuthorizationCheck: React.FC<WithAuthorizationCheckProps> = ({
  requiredPermissions,
  children,
}) => {
  const authContext = useContext(AuthContext);

  const permissions = authContext && authContext.permissions

  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  loaderService.hideSync(); // close any opened loader

  useEffect(() => {
    if (permissions) {
      setIsLoading(false);
      setIsAuthorized(authContext && authContext.checkPermission(requiredPermissions));
    }
  }, [permissions, requiredPermissions]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="loading-message">Loading permissions ...</div>
      </div>
    );
  }


  return isAuthorized ? <><Suspense fallback={
  <>
    <div className="loading-container">
      <div className="loading-message">Loading ...</div>
    </div>
  </>}>{children}</Suspense></> : <UnauthorizedPage></UnauthorizedPage>;
};

export default WithAuthorizationCheck;
