// TeamsAuthService is a singleton so it can retain the user's
// state independent of React state. This module exports the single
// instance of the service rather than the service class; just use it,
// don't new it up.
import * as microsoftTeams from "@microsoft/teams-js";
import { authorizeToken } from "../redux/Action/ApplicationAction";
import jwtDecode from "jwt-decode";

class TeamsAuthService {
  authState: {
    userName: string;
    accessToken: string | null;
    refreshToken: string | null;
    expiresOn: number;
    isTeamsApp: boolean;
    hasErrors: boolean;
  };

  constructor() {
    this.authState = {
      userName: "",
      accessToken: null,
      expiresOn: Date.now(),
      refreshToken: null,
      isTeamsApp: false,
      hasErrors: false,
    };
    var existingToken = localStorage.getItem("token");
    if (existingToken) {
      this.authState.accessToken = existingToken;
      this.authState.refreshToken = localStorage.getItem("refreshtoken");
      var existingTokenDetails = jwtDecode<any>(existingToken);
      this.authState.userName = localStorage.getItem("userName") || existingTokenDetails?.UserName;
      this.authState.expiresOn = existingTokenDetails?.exp * 1000 || Date.now();
      this.authState.isTeamsApp = window.parent !== window.self ? true : false;
    }
  }

  // Determine if someone is logged in
  isLoggedIn() {
    return Date.now() < this.authState.expiresOn;
  }

  async authorizeAndSetTokenLocalStorage(azureToken: string) {
    try {
      const response = await authorizeToken(azureToken);
      localStorage.setItem("token", response?.data?.token);
      localStorage.setItem("refreshtoken", response?.data?.refreshToken);
    } catch (error) {
      this.authState = {
        userName: "",
        accessToken: null,
        expiresOn: Date.now(),
        refreshToken: null,
        isTeamsApp: true,
        hasErrors: true,
      };
      localStorage.clear();
    }
  }

  // Call this to get an access token
  async getAccessToken(){
    await microsoftTeams.app.initialize();
    try {
      const res = await microsoftTeams.authentication
        .getAuthToken();
      await this.authorizeAndSetTokenLocalStorage(res).then(() => {
        var tokenDetails = jwtDecode<any>(res);
        this.authState.userName = tokenDetails?.name;
        localStorage.setItem("userName", this.authState.userName);
        this.authState.expiresOn = tokenDetails?.exp * 1000 || Date.now();
        this.authState.hasErrors = false;
      });
    } catch (error) {
      this.authState.hasErrors = true;
    }
  }
}

export default new TeamsAuthService();
