export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_CLIENT_ID || "",
      authority: process.env.REACT_APP_AUTHORITY || "", 
      redirectUri: process.env.REACT_APP_APP_URL + "/accounts",
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: "localStorage", 
      storeAuthStateInCookie: true,
    },
    system: {
      allowRedirectInIframe: true
    }
  };
  
  export const loginRequest = {
   scopes: ["User.Read"]
  };
 
