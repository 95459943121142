export const appModules = {
  ACCOUNT : 'ACCOUNT',
  PROJECT:'PROJECT',
  EMPLOYEES:'EMPLOYEES',
  CONTACT:'CONTACT',
  TIMELINES:'TIMELINES',
  RESOURCES:'RESOURCES',
  SKILL_TRACKING:'SKILL_TRACKING',
  DOCUMENTS:'DOCUMENTS',
  PMO_DOCUMENTS:'PMO_DOCUMENTS',
  MONTHLY_REPORTS:'MONTHLY_REPORTS',
  PROJECT_DOCUMENTS:'PROJECT_DOCUMENTS'
};

export const PROBABILITIES : any = [
  { id: 1, name: '22' },
  { id: 2, name: '50' },
  { id: 3, name: '75' },
  { id: 4, name: '100' }
];
export const PROJECTTYPES: any = [
  { id: 1, name: 'Client' },
  { id: 2, name: 'Internal' }
];
export const REQUESTTYPES: any = [
  { id: 1, name: 'Immediate Need' },
  { id: 2, name: 'Future Opportunity' }
];
export const RESOURCEREQUESTSTATUS: any = [
  { id: 1, name: 'Open' },
  { id: 2, name: 'In-progress' },
  { id: 3, name: 'Identify' },
  { id: 4, name: 'On hold' },
  { id: 5, name: 'Cancelled' },
  { id: 6, name: 'Fulfilled' },
  { id: 7, name: 'Referred To TA' },
  { id: 8, name: 'Partially Fulfilled' }
];
export const RESOURCETYPE: any = [
  { id: 0, name: 'Recommended' },
  { id: 1, name: 'Assigned' }
];
export const EXPERIENCES: any = [
  { id: 1, name: 'Trainee' },
  { id: 2, name: '0-2' },
  { id: 3, name: '2-4' },
  { id: 4, name: '4-6' },
  { id: 5, name: '6-8' },
  { id: 6, name: '8+' }
];
export const GIVEUPSTATUS: any = [
  { id: 1, name: 'No Resource Available' },
  { id: 2, name: 'Client Not Responding' },
  { id: 3, name: 'Client Need More Time' }
];
export const PRIORITIES : any = [
  { id: 1, name: 'Low' },
  { id: 2, name: 'Medium' },
  { id: 3, name: 'High' },
  { id: 4, name: 'Urgent' }
];

export const DURATIONS: any = [
  { id: 1, name: '< 3 Months' },
  { id: 2, name: '< 6 Months' },
  { id: 3, name: '6 Months - 1 Year' },
  { id: 4, name: '> 1 Year' }
];
export const REQUESTPURPOSES: any = [
  { id: 1, name: 'Billable' },
  { id: 2, name: 'Shadow' },
  { id: 3, name: 'Internal' },
  { id: 4, name: 'Replacement' },
  { id: 5, name: 'Talent Pool' }
];

export const TIMELINE_PROPTYPES = {
  Accounts: "Accounts",
  Project: "Project",
  Resource:"Resource",
  Employee:"Employee",
  ResourceRequest:"Resource Request"
} as const;

export type TimelinePropTypes = typeof TIMELINE_PROPTYPES[keyof typeof TIMELINE_PROPTYPES];

export enum TimeLineRequestTypeIdEnum {
  ACCOUNT = 1,
  PROJECT = 2,
  RESOURCE = 3,
  EMPLOYEE = 5,
  RESOURCE_REQUEST = 9
}
