import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router";
import SVG from "react-inlinesvg";
import { useMsal } from "@azure/msal-react";
import {
  TgHeader,
  TgSideNavBar,
  TgLoader,
  TgToasts,
} from "@tg/react-ui-kit";
import "./layout.scss";
import { UserDetails } from "../../../App";
import TeamsAuthService from "../../../services/TeamsAuthService";
import NotificationDropdown from "../Notifications/NotificationDropDown" ;
import { modalService } from "@tg/react-ui-kit";

function Layout() {
  const { instance, accounts } = useMsal();
  const userData = useContext<any>(UserDetails);
  const userName = userData.name ?? TeamsAuthService.authState.userName;
  const [hasErrors, setHasErrors] = useState<boolean>(
    TeamsAuthService.authState.hasErrors
  );
  const pageDetails = useSelector((state: any) => state.pageDetails);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [refresh, doRefresh] = useState(true);
  const fetchLogouStatus = useSelector((state: any) => state.isLogout.isLogout);
  const navigate = useNavigate();
  
  const menuItems = [
    {
      logoSrc: process.env.PUBLIC_URL + "/assets/icons/account.svg",
      name: "Accounts",
      route: "accounts",
    },
    {
      logoSrc: process.env.PUBLIC_URL + "/assets/icons/projects.svg",
      name: "Projects",
      route: "projects",
    },
    {
      logoSrc: process.env.PUBLIC_URL + "/assets/icons/employee.svg",
      name: "Employee",
      route: "employee",
    },
    {
      logoSrc: process.env.PUBLIC_URL + "/assets/icons/resource.svg",
      name: "Resource requests",
      route: "resource-requests",
    },
  ];
  const handleLogout = () => {
    if (!TeamsAuthService.authState.isTeamsApp) {
      const logoutRequest = {
        account: accounts && accounts[0],
        onRedirectNavigate: (url: string) => {
          return false;
        },
      };
      instance.logoutRedirect(logoutRequest);
    }
    localStorage.clear();
  };
  useEffect(() => {
    if (fetchLogouStatus) {
      // close exisitng pop-ups
      modalService.close();
      navigate("/login");
      handleLogout();
    }
  }, [fetchLogouStatus]);

  useEffect(() => {
    if (hasErrors) {
      navigate("/unauthorized");
    }
  }, [hasErrors]);


  const ProfileDropdownListTemplate = () => {
    return (
      <>
        <div
          className="align-items-center d-flex justify-content-between buzz-dropdown-list"
          onClick={() => {
            handleLogout();
          }}
        >
          <div style = {{color : "#FF5F5F"}}>
          Logout
          </div>
          <div className="ms-3">
          <SVG src={process.env.PUBLIC_URL + "/assets/icons/logout.svg"}>
          </SVG>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <TgHeader
        pageTitle={pageDetails.pageTitle}
        logoSrc={process.env.PUBLIC_URL + "/assets/icons/LogoLight.svg"}
        breadCrumb={pageDetails.breadCrumb}
        userName = {userName || ""}
        showSearch={true}
        showNotification={true}
        notificationCount={unreadNotificationCount}
        notificationDropdownTemplate={
          <NotificationDropdown setUnreadNotifCount={setUnreadNotificationCount} refresh = {refresh}/>
        }
        profileDropdownlistTemplate={
          TeamsAuthService.authState.isTeamsApp ? null : (
            <ProfileDropdownListTemplate />
          )
        }
        onNotificationClick={() => {}}
      ></TgHeader>
      <TgLoader />
      <TgToasts />
      <TgSideNavBar
        menuItems={menuItems}
        logoSrc={process.env.PUBLIC_URL + "/assets/icons/Logo.svg"}
        user={{ name: userName || "" }}
        profileDropdownlistTemplate={
          TeamsAuthService.authState.isTeamsApp ? null : (
            <ProfileDropdownListTemplate />
          )
        }
        showNotification={true}
        notificationCount={unreadNotificationCount}
        notificationDropdownTemplate={
          <NotificationDropdown
            setUnreadNotifCount={setUnreadNotificationCount}
            refresh = {refresh}
          />
        }
        onNotificationClick={() => {}}
      ></TgSideNavBar>
      <div className="content">
        <div id="router-outlet">
          <Outlet />
        </div>
      </div>
      <div className="bottom-border">
        <div className="border-background right">
          <div className="b-right"></div>
        </div>
        <div className="border-background left">
          <div className="b-left"></div>
        </div>
      </div>
    </div>
  );
}
export default Layout;
