

export const API_SLUG_CONSTANTS = {
  GET_COUNTRIES: "/MasterData/GetCountries",
  GET_ORGANIZATION_SIZES: "/MasterData/GetOrganizationSizes",
  GET_ALL_ACCOUNT_TYPES: "/MasterData/GetAllAccountTypes",
  GET_ALL_ACCOUNT_STATUSES: "/MasterData/GetAllAccountStatuses",
  GET_ALL_PROJECT_STATUSES: "/MasterData/GetAllProjectStatuses",
  GET_ALL_CONTACT_STATUSES: "/MasterData/GetAllContactStatuses",
  GET_ALL_PROJECTS: "/Project/GetAllProjects",
  GET_ALL_PROJECT_NAMES: "/MasterData/GetProjects",
  GET_ALL_SKILL_NAMES:"/MasterData/GetSkills",
  GET_FUTURE_PROJECT_STATUS: "/MasterData/GetFutureStatus",
  GET_FUTURE_PROJECTIONS: "/Resource/GetFutureProjections",
  CREATE_FUTURE_PROJECTIONS: "/Resource/AddFutureProjections",
  DELETE_FUTURE_PROJECTIONS: "/Resource/DeleteFutureProjection",
  GET_FUTURE_PROJECT_STATUS_DETAILS: "/MasterData/GetFutureStatusDetails",
  GET_FUTURE_PROJECT_EXTRA_DETAILS: "/MasterData/GetFutureShadowDetails",
  EDIT_FUTURE_DETAILS:"/Resource/UpdateEmployeeFutureProjections",
  GET_ALL_EMPLOYEES: "/Employee/GetAllEmployees",
  GET_ALL_EMPLOYEES_WITH_STATUS: "/Employee/GetEmployees",
  GET_ALL_PROJECT_TYPES: "/MasterData/GetAllProjectTypes",
  GET_ALL_PROJECT_CATEGORIES: "/MasterData/GetAllProjectCategories",
  GET_ALL_BUSINESS_DETAILS: "/MasterData/GetAllBussinessDetails",
  GET_ALL_TIMEZONES: "/MasterData/GetAllTimeZones",
  GET_ALL_PLATFORMS: "/MasterData/GetAllPlatForms",
  GET_ALL_PROJECT_TAGS: "/MasterData/GetAllProjectTags",
  GET_ALL_PROJECT_TOOLS: "/MasterData/GetAllTools",
  GET_ALL_ASSOCIATION_TYPES: "/MasterData/GetAllAssociationTypes",
  GET_ALL_CONTACT_TYPES: "/MasterData/GetAllContactTypes",
  GET_STANDARD_BILLING_HOURS: "/MasterData/GetStandardBillingHours",
  GET_ALL_SHIFT_TYPES: "/MasterData/GetAllShiftTimings",
  GET_ALL_SHADOW_TYPES: "/MasterData/GetAllShadowTypes",
  GET_ALL_TIMELINE_FIELDS: "/MasterData/GetTimelineFields?timelineTypeId=",
  GET_ALL_PRACTICES:"/MasterData/GetAllPractices",
  GET_PROJECT_NAME_BY_SEARCH:
    "/Project/SearchProjectByProjectName?projectName=",
  GET_ACCOUNT_TIMELINES: "/Timeline/GetAccountTimelines",
  GET_PROJECT_TIMELINES: "/Timeline/GetProjectTimelines",
  GET_RESOURCE_TIMELINES: "/Timeline/GetResourceTimelines",
  GET_RESOURCE_REQUEST_TIMELINES: "/Timeline/GetResourceRequestTimelines",
  FETCH_TIMELINE_FORMATS_BY_ACCOUNT_ID: "/MasterData/GetAllTimelineFormats",
  FETCH_ACCOUNTS: "/Account/Fetch",
  FETCH_ACCOUNT_BY_ID: "/Account/GetById?accountId=",
  CREATE_ACCOUNT: "/Account/AddAccount",
  UPDATE_ACCOUNT: "/Account/UpdateAccount",
  FETCH_CONTACT: "/Contact/GetAccountContacts",
  SEARCH_PROJECT_BY_NAME: "/Project/SearchProjectByName",
  CREATE_PROJECT: "/Project/AddProject",
  UPDATE_PROJECT: "/Project/UpdateProject",
  FETCH_CONTACT_BY_ID: "/Contact/GetContactById?ContactId=",
  CREATE_ACCOUNT_CONTACT: "/Contact/AddAccountContact",
  UPDATE_CONTACT: "/Contact/UpdateContact",
  FETCH_ROLES: "/Roles",
  FETCH_ALLOCATION_CATEGORY: "/MasterData/GetAllBillabilityStatuses",
  FETCH_PROJECTS_BY_ACCOUNT_ID: "/Account/GetProjectsByAccountId",
  FETCH_RESOURCE_BY_PROJECT_ID: "/Employee/GetEmployeesByProjectId?projectId=",
  ADD_RESOURCE_TO_PROJECT: "/Resource/AddResource",
  FETCH_PROJECT_BY_PROJECT_NAME:
    "/Project/SearchProjectByProjectName?projectName=",
  FETCH_EMPLOYEE_BY_EMPLOYEE_NAME_OR_ID:"/Employee/SearchEmployeeByNameOrId?employeeNameOrId=",
  UPDATE_ACCOUNT_RESOURCE: "/Resource/UpdateResource",
  FETCH_PROJECT_CONTACTS: "/Contact/GetProjectContacts",
  FETCH_PROJECT_BY_ID: "/Project/GetProjectById?projectId=",
  CREATE_PROJECT_CONTACT: "/Contact/AddProjectContact",
  FETCH_RESOURCES: "/Resource/GetAccountResources",
  FETCH_PROJECT_RESOURCES: "/Resource/GetProjectResources",
  UPDATE_ACCOUNT_CONTACT: "/Contact/UpdateContact",
  FETCH_RESOURCE_BY_ACCOUNT_ID: "/Resource/GetAccountResources",
  FETCH_EMPLOYEE_BY_ID: "/Employee/GetEmployeeOverviewById?employeeId=",
  AUTHORIZE_TOKEN: "/User/Authorize?azureAccessToken=",
  GET_REFRESH_TOKEN: "/User/RefreshToken",
  // UPDATE_RESOURCE:""
  FETCH_EMPLOYEES:"/Employee/GetAllEmployees",
  FETCH_EMPLOYEE_PROJECTS: "/Employee/GetProjectsByEmployeeId",
  FETCH_EMPLOYEE_UTILIZATION_STATUS: "/Employee/EmployeeUtilizationStatus?employeeId=",
  GET_EMPLOYEE_SKILLS: "/EmployeeSkillTracking/GetEmployeeSkillsByEmployeeId?employeeId=",
  GET_EMPLOYEE_CERTIFICATIONS: "/EmployeeSkillTracking/GetEmployeeCertificationsByEmployeeId?employeeId=",
  GET_ACCOUNT_EXCEL: "/Account/ExportAccountsData",
  GET_PROJECT_EXCEL: "/Project/ExportProjectsData",
  GET_EMPLOYEE_EXCEL: "/Employee/ExportEmployeesData",
  GET_USER_PERMISSIONS: "/User/GetUserPermissions",
  GET_USER_NOTIFICATIONS: "/Notifications/GetNotifications",
  UPDATE_USER_NOTIFICATION_STATUS: "/Notifications/UpdateUserNotificationStatus",
  CREATE_EMPLOYEE_DOCUMENT:"/Employee/AddEmployeeDocument",
  GET_EMPLOYEE_DOCUMENT:"/Employee/GetEmployeeDocuments?employeeId=",
  DOWNLOAD_EMPLOYEE_DOCUMENT:"/Employee/DownloadEmployeeDocument",
  DELETE_EMPLOYEE_DOCUMENT:"/Employee/DeleteEmployeeDocument",
  ADD_EMPLOYEE_ACTIVITY:"/Resource/AddEmployeeActivity",
  UPDATE_EMPLOYEE_ACTIVITY:"/Resource/UpdateEmployeeActivity",
  GET_BILLABLE_EMPLOYEE_DETAILS:"/Resource/GetEmployeeProjectMapDetails",
  GET_EMPLOYEE_ACTIVITY:"/Resource/GetEmployeeActivity",
  GET_EMPLOYEE_ACTIVITYYEARS:"/MasterData/GetAllEmployeeActivityYear",
  GET_EMPLOYEE_ACTIVITYMONTHS:"/MasterData/GetAllTimeperiod",
  GET_PROJECT_DOCUMENTS: '/Document/GetProjectDocuments',
  GET_DOCUMENT_PREVIEW: '/Document/GetDocumentUrlForPreview',
  DELETE_PROJECT_DOCUMENTS: '/Document/DeleteProjectDocument',
  DOWNLOAD_PROJECT_DOCUMENTS: '/Document/DownloadDocument',
  CREATE_PROJECT_DOCUMENT: '/Document/AddProjectDocument',
  CREATE_RESOURCE_REQUEST:'/ResourceRequest/AddorUpdateResourceRequest',
  GET_ALL_RESOURCE_REQUESTS:'/ResourceRequest/GetResourceRequests',
  FETCH_RESOURCE_REQUEST_BY_REQUEST_ID:'/ResourceRequest/GetResourceRequestById?getResourceRequestId=',
  GET_EMPLOYEE_UTILIZATION_ON_SPECIFIC_DATE:"/Employee/GetEmployeeUtilizationStatusOnDates",
  ACCOUNT_ENDDATE_VALIDATION_WHILE_UPDATING: "/Account/EndDateValidationWhileUpdating",
  PROJECT_ENDDATE_VALIDATION_WHILE_UPDATING: "/Project/ProjectEndDateValidation",
  GET_RECOMMENDED_AND_ASSIGNED_RESOURCES_BY_REQUESTID: "/ResourceRequest/GetRecommendedAndAssignedResourcesByRequestId",
  GET_EMPLOYEE_PROJECT_MAP_DETAILS_BY_RESOURCE_REQUEST_ID: "/ResourceRequest/GetEmployeeProjectMapDetailsByResourceRequestId?resourceRequestId="
};
