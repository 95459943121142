
import ResourceRequest from "../../models/api/requests/ResourceRequest";

const fetchResourceRequestData: any = {
  data: new ResourceRequest(),
  error: '',
  status: 'idle'
};
const initialValues: any = {
  data: new ResourceRequest(),
  error: '',
  status: 'idle'
};
export const fetchResourceResourceRequestsReducer = (
  state = fetchResourceRequestData,
  action: any
) => {
  switch (action.type) {
    case "FETCH_RESOURCE_REQUESTS":
      return {
        ...state,
        data: { ...action.payload },
        error: "",
        status: action.status,
      };
    case "FETCH_RESOURCE_REQUESTS_LOADING":
      return {
        data: fetchResourceRequestData.data,
        error: "",
        status: action.status,
      };
    case "FETCH_RESOURCE_REQUESTS_ERROR":
      return {
        data: [],
        error: action.payload,
        status: action.status,
      };
    default:
      return state;
  }
};
export const resourceRequestFilterBodyReducer = (
  state = [],
  action: any
) => {
  switch (action.type) {
    case "UPDATE_REQUEST_FILTER_BODY":
      return [...action.payload];
    case "RESET_REQUEST_FILTER_BODY":
      return [];
    default:
      return state;
  }
}

export const fetchResourceByRequestIdReducer = (
  state = initialValues ,
  action: any
) => {
  switch (action.type) {
    case "FETCH_RESOURCE_REQUEST_BY_REQUEST_ID":
      return {
        data: action.payload,
        error: "",
        status: action.status,
      };
    case "RESET_RESOURCE_REQUEST_INFO":
      return {
        data: new ResourceRequest(),
        error: '',
        status: 'idle'
      };
    case "FETCH_RESOURCE_REQUEST_BY_REQUEST_ID_LOADING":
      return {
        data: [],
        error: "",
        status: action.status,
      };
    case "FETCH_RESOURCE_REQUEST_BY_REQUEST_ID_ERROR":
      return {
        data: [],
        error: action.payload,
        status: action.status,
      };
    default:
      return state;
  }
};

export const fetchEmployeeProjectMapDetailsByResourceRequestReducer = (
  state = initialValues ,
  action: any
) => {
  switch (action.type) {
    case "FETCH_EMPLOYEE_PROJECT_MAP_DETAILS_BY_REQUEST_ID":
      return {
        data: action.payload,
        error: "",
        status: action.status,
      };
    case "FETCH_EMPLOYEE_PROJECT_MAP_DETAILS_BY_REQUEST_ID_INFO":
      return {
        data: new ResourceRequest(),
        error: '',
        status: 'idle'
      };
    case "FETCH_EMPLOYEE_PROJECT_MAP_DETAILS_BY_REQUEST_ID_LOADING":
      return {
        data: [],
        error: "",
        status: action.status,
      };
    case "FETCH_EMPLOYEE_PROJECT_MAP_DETAILS_BY_REQUEST_ID_ERROR":
      return {
        data: [],
        error: action.payload,
        status: action.status,
      };
    default:
      return state;
  }
};

export const fetchRecommendedAndAssignedResourcesByRequestIdReducer = (
  state = initialValues ,
  action: any
) => {
  switch (action.type) {
    case "FETCH_RECOMMENDED_AND_ASSIGNED_RESOURCES_BY_REQUEST_ID":
      return {
        data: action.payload,
        error: "",
        status: action.status,
      };
    case "FETCH_RECOMMENDED_AND_ASSIGNED_RESOURCES_BY_REQUEST_ID_INFO":
      return {
        data: new ResourceRequest(),
        error: '',
        status: 'idle'
      };
    case "FETCH_RECOMMENDED_AND_ASSIGNED_RESOURCES_BY_REQUEST_ID_LOADING":
      return {
        data: [],
        error: "",
        status: action.status,
      };
    case "FETCH_RECOMMENDED_AND_ASSIGNED_RESOURCES_BY_REQUEST_ID_ERROR":
      return {
        data: [],
        error: action.payload,
        status: action.status,
      };
    default:
      return state;
  }
};

export const recommendedAndAssignedResourceFilterBodyReducer = (
  state = [],
  action : any
) => {
  switch(action.type) {
    case "UPDATE_RECOMMENDED_AND_ASSIGNED_RESOURCE_FILTER_BODY":
      return [...action.payload];
    case "RESET_RECOMMENDED_AND_ASSIGNED_RESOURCE_FILTER_BODY":
      console.log('reset');
      return [];
    default:
      return state;
  }
}