import Contact from "../../models/api/responses/Contact";
import Account from "../../models/api/requests/Account";
import FetchAccount from "../../models/api/responses/FetchAccount";
import ContactData from "../../models/api/requests/ContactData";
import FetchContact from "../../models/api/responses/FetchContacts";
import {UpdateContact} from "../../models/api/requests/UpdateContact";

const initialValues: any = {
  data: [],
  error: "",
  status:"idle",
};
const accountInitialValues: any = {
  data: new Account(),
  error: "",
  status:"idle",
};
const contactResponseInitialValues: any = {
  data: new Contact(),
  error: "",
  status:"idle",
};
const fetchAccountsData: any = {
  data: new FetchAccount(),
  error: "",
  status:"idle",
};
const fetchContactsData: any = {
  data: new FetchContact(),
  error: "",
  status:"idle",
};
const contactRequestInitialValues: any = {
  data: new ContactData(),
  error: "",
  status:"idle",
};
const updateRequestInitialValues: any = {
  data: new UpdateContact(),
  error: "",
  status:"idle",
};

export const contactTypeReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_CONTACT_TYPE":
      return {
        data: [...action.payload],
        error: "",
        status: action.status,
      };
    case "FETCH_CONTACT_TYPE_LOADING":
      return {
        data: [],
        error: "",
        status: action.status,
      };
    case "FETCH_CONTACT_TYPE_ERROR":
      return {
        data: [],
        error: action.payload,
        status: action.status,
      };
    default:
      return state;
  }
};
export const fetchAllProjectsReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_ALL_PROJECTS":
      return {
        ...state,
        data: [...action.payload],
        error: "",
        status: action.status,
      };
    case "FETCH_ALL_PROJECTS_LOADING":
      return {
        data: [],
        error: "",
        status: action.status,
      };
    case "FETCH_ALL_PROJECTS_ERROR":
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export const associationTypeReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_ASSOCIATION_TYPE":
      return {
        data: [...action.payload],
        error: "",
        status: action.status,
      };
    case "FETCH_ASSOCIATION_TYPE_LOADING":
      return {
        data: [],
        error: "",
        status: action.status,
      };
    case "FETCH_ASSOCIATION_TYPE_ERROR":
      return {
        data: [],
        error: action.payload,
        status: action.status,
      };
    default:
      return state;
  }
};
export const searchProjectNameReducer = (
  state = initialValues,
  action: any
) => {
  switch (action.type) {
    case "FETCH_PROJECT_NAME":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_PROJECT_NAME_LOADING":
      return {
        data: [],
        error: "",
        status: action.status,
      };
    case "FETCH_PROJECT_NAME_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};
export const accountTypeReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_ACCOUNT_TYPE":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_ACCOUNT_TYPE_LOADING":
      return {
        data: [],
        error: "",
        status: action.status,
      };
    case "FETCH_ACCOUNT_TYPE_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};
export const accountStatusReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_ACCOUNT_STATUS":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_ACCOUNT_STATUS_LOADING":
      return {
        data: [],
        error: "",
        status: action.status,
      };
    case "FETCH_ACCOUNT_STATUS_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};
export const contactStatusReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_CONTACT_STATUS":
      return {
        data: [...action.payload],
        error: "",
      };
    case "FETCH_CONTACT_STATUS_ERROR":
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const shiftTypesReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_ALL_SHIFT_TYPES":
      return {
        data: [...action.payload],
        error: "",
      };
    case "FETCH_ALL_SHIFT_TYPES_ERROR":
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export const shadowTypesReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_ALL_SHADOW_TYPES":
      return {
        data: [...action.payload],
        error: "",
      };
    case "FETCH_ALL_SHADOW_TYPES_ERROR":
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export const accountByIdReducer = (
  state = accountInitialValues,
  action: any
) => {
  switch (action.type) {
    case "FETCH_ACCOUNT_BY_ID":
      return {
        data: action.payload,
        error: "",
        status:action.status,
      };
      case "FETCH_ACCOUNT_BY_ID_LOADING":
        return {
          data: accountInitialValues.data,
          error: "",
          status:action.status,
        };
    case "FETCH_ACCOUNT_BY_ID_ERROR":
      return {
        data: accountInitialValues.data,
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};
export const contactByIdReducer = (
  state = contactResponseInitialValues,
  action: any
) => {
  switch (action.type) {
    case "FETCH_CONTACT_BY_ID":
      return {
        data: action.payload,
        error: "",
        status:action.status,
      };
    case "FETCH_CONTACT_BY_ID_LOADING":
      return {
        data: contactResponseInitialValues.data,
        error: "",
        status:action.status,
      };
    case "FETCH_CONTACT_BY_ID_ERROR":
      return {
        data: contactResponseInitialValues.data,
        error: action.payload,
        status:action.status,
      };
    case "FETCH_CONTACT_BY_ID_RESET":
      return contactResponseInitialValues;
    default:
      return state;
  }
};

export const createAccountReducer = (
  state = accountInitialValues,
  action: any
) => {
  switch (action.type) {
    case "CREATE_ACCOUNT":
      return {
        data: { ...state.data, ...action.payload },
        error: "",
        status:action.status,
      };
    case "CREATE_ACCOUNT_LOADING":
      return {
        data: accountInitialValues.data,
        error: "",
        status:action.status,
      };
    case "CREATE_ACCOUNT_ERROR":
      return {
        data: accountInitialValues.data,
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};
export const createAccountContactReducer = (
  state = contactRequestInitialValues,
  action: any
) => {
  switch (action.type) {
    case "CREATE_ACCOUNT_CONTACT":
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        error: "",
        status:action.status,
      };
    case "CREATE_ACCOUNT_CONTACT_LOADING":
      return {
        data: contactResponseInitialValues.data,
        error: "",
        status:action.status,
      };
    case "CREATE_ACCOUNT_CONTACT_ERROR":
      return {
        data: contactResponseInitialValues.data,
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const updateContactReducer = (
  state = updateRequestInitialValues,
  action: any
) => {
  switch (action.type) {
    case "UPDATE_CONTACT":
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        error: "",
        status:action.status,
      };
    case "UPDATE_CONTACT_LOADING":
      return {
        data: updateRequestInitialValues.data,
        error: "",
        status:action.status,
      };
    case "UPDATE_CONTACT_ERROR":
      return {
        data: updateRequestInitialValues.data,
        error: "",
        status:action.status,
      };
    default:
      return state;
  }
};
export const businessDetailsReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_BUSINESS_DETAILS":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_BUSINESS_DETAILS_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_BUSINESS_DETAILS_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const countryReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_COUNTRY":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_COUNTRY_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_COUNTRY_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

// export const currencyTypeReducer = (
//   state = initialValues,
//   action: any
// ) => {
//   switch (action.type) {
//     case "FETCH_CURRENCY_TYPE":
//      return {
//     ...state,
//     data : [...state.data , ...action.payload],
//     error : ''
//    status:action.status,
// };
//    case "FETCH_CURRENCY_TYPE_ERROR":
//    return {
//     data : [],
//     error : action.payload
//    status:action.status,
// };
//     default:
//       return state;
//   }
// };

export const fetchAccountsReducer = (
  state = fetchAccountsData,
  action: any
) => {
  switch (action.type) {
    case "FETCH_ACCOUNTS":
      return {
        ...state,
        data: { ...action.payload },
        error: "",
        status:action.status,
      };
    case "FETCH_ACCOUNTS_LOADING":
      return {
        data: fetchAccountsData.data,
        error: "",
        status:action.status,
      };
    case "FETCH_ACCOUNTS_ERROR":
      return {
        data: fetchAccountsData.data,
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};
export const fetchContactsReducer = (
  state = fetchContactsData,
  action: any
) => {
  switch (action.type) {
    case "FETCH_CONTACTS":
      return {
        data: { ...action.payload },
        error: "",
        status:action.status,
      };
    case "FETCH_CONTACTS_LOADING":
      return {
        data: fetchContactsData.data,
        error: "",
        status:action.status,
      };
    case "FETCH_CONTACTS_ERROR":
      return {
        data: fetchContactsData.data,
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const setAccountIdReducer = (state = 0, action: any) => {
  switch (action.type) {
    case "SET_ACCOUNT_ID":
      return action.payload;
    default:
      return state;
  }
};

export const organizationSizeReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_ORGANIZATION_SIZE":
      return {
        ...state,
        data: [...state.data, ...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_ORGANIZATION_SIZE_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_ORGANIZATION_SIZE_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const fetchResourcesReducer = (
  state = initialValues,
  action: any
) => {
  switch (action.type) {
    case "FETCH_RESOURCES":
      return {
        data: action.payload,
        error: "",
        status:action.status,
      };
    case "FETCH_RESOURCES_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_RESOURCES_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const fetchRolesReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_ROLES":
      let sortedRoles = [...action.payload].sort((a: any, b: any) => {
        const roleA = a.role.toLowerCase();
        const roleB = b.role.toLowerCase();

        if (roleA < roleB) return -1;
        if (roleA > roleB) return 1;
        return 0;
      });
      return {
        data: sortedRoles,
        error: "",
        status:action.status,
      };
    case "FETCH_ROLES_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_ROLES_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const fetchEmployeesWithStatusReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_EMPLOYEE_WITH_STATUS":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_EMPLOYEE_WITH_STATUS_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_EMPLOYEE_WITH_STATUS_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const fetchAllocationCategoryReducer = (
  state = initialValues,
  action: any
) => {
  switch (action.type) {
    case "FETCH_ALLOCATION_CATEGORY":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_ALLOCATION_CATEGORY_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_ALLOCATION_CATEGORY_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const fetchProjectsByAccountIdReducer = (
  state = initialValues,
  action: any
) => {
  switch (action.type) {
    case "FETCH_PROJECTS_BY_ACCOUNT_ID":
      return {
        data: action.payload,
        error: "",
        status:action.status,
      };
    case "FETCH_PROJECTS_BY_ACCOUNT_ID_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_PROJECTS_BY_ACCOUNT_ID_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
      case "FETCH_PROJECTS_BY_ACCOUNT_ID_RESET":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    default:
      return state;
  }
};

export const fetchResourceByProjectIdReducer = (
  state = initialValues,
  action: any
) => {
  switch (action.type) {
    case "FETCH_RESOURCE_BY_PROJECT_ID":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_RESOURCE_BY_PROJECT_ID_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_RESOURCE_BY_PROJECT_ID_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const fetchProjectsByProjectNameReducer = (
  state = initialValues,
  action: any
) => {
  switch (action.type) {
    case "FETCH_PROJECT_BY_PROJECT_NAME":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_PROJECT_BY_PROJECT_NAME_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_PROJECT_BY_PROJECT_NAME_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const fetchEmployeeByEmployeeNameReducer = (
  state = initialValues,
  action: any
) => {
  switch (action.type) {
    case "FETCH_EMPLOYEE_BY_EMPLOYEE_NAME":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_EMPLOYEE_BY_EMPLOYEE_NAME_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_EMPLOYEE_BY_EMPLOYEE_NAME_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const addEmployeeToProjectReducer = (
  state = initialValues,
  action: any
) => {
  switch (action.type) {
    case "ADD_RESOURCE_TO_PROJECT":
      return {
        data: action.payload,
        error: "",
        status:action.status,
      };
    case "ADD_RESOURCE_TO_PROJECT_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "ADD_RESOURCE_TO_PROJECT_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const updateAccountResourceReducer = (
  state = initialValues,
  action: any
) => {
  switch (action.type) {
    case "UPDATE_ACCOUNT_RESOURCE":
      return {
        data: { ...action.payload },
        error: "",
        status:action.status,
      };
    case "UPDATE_ACCOUNT_RESOURCE_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "UPDATE_ACCOUNT_RESOURCE_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const editAccountResourceReducer = (
  state = initialValues,
  action: any
) => {
  switch (action.type) {
    case "EDIT_ACCOUNT_RESOURCE":
      return {
        data: { ...action.payload },
        error: "",
        status:action.status,
      };
    case "EDIT_ACCOUNT_RESOURCE_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "EDIT_ACCOUNT_RESOURCE_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};

export const accountFilterBodyReducer = (
  state = [],
  action : any
) => {
  switch(action.type) {
    case "UPDATE_ACCOUNT_FILTER_BODY":
      return [...action.payload];
    case "RESET_ACCOUNT_FILTER_BODY":
      return [];
    default:
      return state;
  }
}

export const activityFilterBodyReducer = (
  state = [],
  action : any
) => {
  switch(action.type) {
    case "UPDATE_ACTIVITY_FILTER_BODY":
      return [...action.payload];
    case "RESET_ACTIVITY_FILTER_BODY":
      return [];
    default:
      return state;
  }
}

export const fetchTimelineFieldReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case "FETCH_TIMELINE_FIELDS":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_TIMELINE_FIELDS_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_TIMELINE_FIELDS_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};


export const fetchEmployeeDocumentsReducer =(state=initialValues,action:any)=>{
  switch (action.type) {
    case "FETCH_EMPLOYEE_DOCUMENTS":
      return {
        data: [...action.payload],
        error: "",
        status:action.status,
      };
    case "FETCH_EMPLOYEE_DOCUMENTS_LOADING":
      return {
        data: [],
        error: "",
        status:action.status,
      };
    case "FETCH_EMPLOYEE_DOCUMENTS_ERROR":
      return {
        data: [],
        error: action.payload,
        status:action.status,
      };
    default:
      return state;
  }
};