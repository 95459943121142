export default class Project {
  projectId?: number;
  projectName?: string;
  accountId?: number;
  accountName?: string;
  projectCode?: string;
  projectStatus?: string;
  projectStartDate?: Date;
  projectEndDate?: Date;
  projectManager?: string;
  timeZone?: string;
  projectOverview?: string;
  projectCategory?: string;
  projectType?: string;
  projectSkills?:  ProjectSkills[];
  applicationLink?:string;
  gitHubLink?:string;
  toolName?:string;
  platform?:string;
  tags?:string;
  accountURL?:string;
  createdDate?:Date;

}

export interface ProjectSkills{
  skillId?: number;
  skillName?: string;
}
