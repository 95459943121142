import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { getPermissions } from "../../redux/Action/ApplicationAction";
import TeamsAuthService from '../../services/TeamsAuthService';



export type Action = 'READ' | 'ADD' | 'EDIT' | 'EXPORT' | 'REQUEST' | 'ASSIGN' | 'RECOMMEND' | 'BYPASS';
export type Module = 'ACCOUNT' | 'PROJECT' | 'EMPLOYEES' | 'CONTACT' | 'TIMELINES' | 'RESOURCES' | 'SKILL_TRACKING' | 'DOCUMENTS' | 'PMO_DOCUMENTS' | 'MONTHLY_REPORTS' | 'PROJECT_DOCUMENTS' | 'RESOURCE_REQUEST'|'EMPLOYEE_ACTIVITY' |'ASSIGNED_REQUESTS';

type Permissions = {
  [key in Module]: Partial<Record<Action, boolean>>;
};

type AuthContextType = {
  permissions: Permissions | null;
  checkPermission: (permissionPairs: { module: Module; action: Action }[]) => boolean;
};

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [permissions, setPermissions] = useState<Permissions | null>(null);
  const [permissionsFromAPI, setPermissionsFromAPI] = useState<any>([]);
  const permissionMapper: Record<number, Action> = {
    1: 'READ',
    2: 'ADD',
    3: 'EDIT',
    4: 'EXPORT',
    5: 'REQUEST',
    6: 'ASSIGN',
    7: 'RECOMMEND',
    8: 'BYPASS'
  };

  const decodePermissions = () => {
    const formattedPermissions: Permissions = {
      ACCOUNT: {},
      PROJECT: {},
      EMPLOYEES: {},
      CONTACT: {},
      TIMELINES: {},
      RESOURCES: {},
      SKILL_TRACKING: {},
      DOCUMENTS: {},
      MONTHLY_REPORTS: {},
      PMO_DOCUMENTS: {},
      PROJECT_DOCUMENTS: {},
      RESOURCE_REQUEST: {},
      EMPLOYEE_ACTIVITY: {},
      ASSIGNED_REQUESTS: {}
    };

    permissionsFromAPI.forEach((permission: any) => {
      const module = permission.componentName.toUpperCase();
      const actions: Partial<Record<Action, boolean>> = {};

      Object.values(permissionMapper).forEach(action => {
        actions[action] = false;
      });

      permission.permissionCategories.forEach((category:any) => {
        const actionName = permissionMapper[category];
        if (actionName) {
          actions[actionName] = true;
        }
      });

      formattedPermissions[module as Module] = actions;
    });
    return formattedPermissions;
  }

  const fetchPermissions = () => {
    getPermissions()
        .then((res:any) => {
          if (res.data) {
            setPermissionsFromAPI(res.data)
          }
        })
        .catch((err: any) => {
          console.error('Error while fetching permissions = ', err)
        })
  }

  const onLoad = () => {
    if (window.parent !== window.self && !TeamsAuthService.authState.isTeamsApp) 
    {
      TeamsAuthService.authState.isTeamsApp = true;
    }
    if (localStorage.getItem("token")) 
    {
      fetchPermissions();
    }
    else if (TeamsAuthService.authState.isTeamsApp)
    {
      TeamsAuthService.getAccessToken().then(() => {
        fetchPermissions();
      });   
    }
  }
  
  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    if (permissionsFromAPI.length > 0) {
      const decodedPermissions = decodePermissions();
      setPermissions(decodedPermissions);
      console.log('decodedPermissions = ', decodedPermissions)
    }
  }, [permissionsFromAPI]);

  const checkPermission = (permissionPairs: { module: Module; action: Action }[]) => {
    for (const { module, action } of permissionPairs) {
      const modulePermissions = permissions?.[module];
      if (modulePermissions && modulePermissions[action]) {
        return true;
      }
    }
    return false;
  };

  return (
    <AuthContext.Provider value={{ permissions, checkPermission }}>
      {children}
    </AuthContext.Provider>
  );
};
