import { isObjectPresent } from "./IsObjectPresent";

export function ConvertToValueObject(obj: any, column?: string) {
  const items: any = [];
  obj &&
    obj.forEach((element: any) => {
      if (column) {
        if (!isObjectPresent(items, element[column]) && element[column]!== null) {
          items.push({
            value: element[column],
          });
        }
      } else {
        if (!isObjectPresent(items, element) && element!==null) {
          items.push({
            value: element,
          });
        }
      }
    });
  return items;
}
