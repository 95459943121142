import React, { useEffect, useMemo, useState } from "react";
import { Modal} from "react-bootstrap";
import FilterBody from "../Filter/filterbody";
import SVG from "react-inlinesvg";
import "./filter.scss"
import { BehaviorSubject } from "rxjs";
import { confirmModal } from "../../../helpers/confirmModal";
interface filterProps{
  data: any[];
  title:string;
  onFilterMount?:() => void;
  onFilterDispatch?: (type: any, payload: any) => void
  view?: boolean;
  storeFilterBody: string;
  rowData?:(e:any) => void;
}

function Filter(props:filterProps) {
  const filtericon = process.env.PUBLIC_URL + "/assets/icons/filter_list.svg";
  const [showModal, isShowModal] = React.useState<boolean>(false);
  const [filterRequest,setRequest]=useState<any>();
  const $dirty = useMemo(() => new BehaviorSubject<boolean>(false), []);
  const [filtersCount, setFilterCount]= useState<number>();
  const initModal = () => {
    return isShowModal(true);
  };
  const closeModal = () => {
    let isDirty = $dirty.getValue();
    if(isDirty){
       confirmModal(
              () => {
                if(props.onFilterMount){
                  props.onFilterMount();
                }
                return isShowModal(false);
              },
              () => {},
              "Are you sure to close the filter? the changes made won't be saved."
            )
    }
    else{
      if(props.onFilterMount){
        props.onFilterMount();
      }
      return isShowModal(false);
    }
  };
  const onModal=(e:any)=>{
    isShowModal(e);
  }
  useEffect(()=>{
    if(props.rowData){
      props.rowData(filterRequest)
    }
  },[filterRequest])
  return (
    <>
    <div 
      className={'smart-filters' + (filtersCount &&  filtersCount > 0 ? ' showCount' : '' )}
      onClick={() => {
        $dirty.next(false);
        initModal();
      }}
      filter-count={filtersCount}
    >
      <span>
          <SVG src={filtericon}
            ></SVG>
      </span>
      <button className="smart-filter-button">
        Filters
      </button>
    </div>
    <div className="filter-container" >
        <Modal show={showModal} onHide={closeModal} backdrop="static" keyboard={false}>
          <Modal.Header closeButton >
          <Modal.Title>
          <div className="card-decarator"></div>
          <div className="card-label">{props.title} Filters</div>
          </Modal.Title>
          </Modal.Header>
            <FilterBody showModal={true}
        onModal={(e: any) => { onModal(e); }}
        data={props.data}
        view={props?.view}
        storeFilterBodyString={props.storeFilterBody}
        onFilterMount = {() => {
        props.onFilterMount && props.onFilterMount()
        }}
        onFilterDispatch={(type:any, payload:any) => {
          if (props.onFilterDispatch) {
            props.onFilterDispatch(type, payload);
          }
        }}
        onCancel = {()=>{closeModal()}}
        $dirty={$dirty}
        rowData={(e:any)=>{
          setRequest(e)
          setFilterCount(e.filters?.length)
        }} 
        />
      </Modal>
      </div>
      </>
  );
}
export default Filter;
