import React from "react";
import "./login.scss";
import SVG from "react-inlinesvg";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { loaderService } from "@tg/react-ui-kit";

export default function Login() {
  const { instance } = useMsal();
  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="login-container d-flex">
      <div className="left-container bg-white d-flex flex-column align-items-center justify-content-center">
        <div className="align-items-center d-flex flex-column login-block">
          <div className="logo">
            <SVG
              src={process.env.PUBLIC_URL + "/assets/icons/loginLogo.svg"}
            ></SVG>
          </div>
          <div className="d-flex login-header">Login</div>

          <div
            className="login-outlook p-3 text-center"
            onClick={() => {
              loaderService.addSync();
              handleLogin();
            }}
          >
            <span className="pe-3">
              <SVG
                src={
                  process.env.PUBLIC_URL + "/assets/icons/microsoftOutlook.svg"
                }
              ></SVG>
            </span>
            <span>Login with Outlook</span>
          </div>
        </div>
      </div>
      <div className="align-items-center d-flex justify-content-center right-container flex-column">
        <div className="image d-flex">
          <img
            src={process.env.PUBLIC_URL + "/assets/images/login.png"}
            alt=""
          />
        </div>
        <div className="login-content">
          <div className="login-title text-center">BUZZ</div>
          <div className="login-subtitle px-3 text-center">
          </div>
        </div>
      </div>
    </div>
  );
}
